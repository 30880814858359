import React, {useState} from "react"
import { TextField, Button, Grid, makeStyles, Typography,Container, Paper } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles(theme => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function ForgotPassword(){
    const [email, setEmail] = useState("")
    const [error,setError] = useState("")
    const [emailSent, setEmailSent] = useState(false)
    const classes = useStyles()

    function forgotPassword(){
        var auth = firebase.auth();
        auth.sendPasswordResetEmail(email).then(function() {
          // Email sent.
          setEmailSent(true)
          console.log("email sent")
        }).catch(function(error) {
          // An error happened.
          setError("There is no user with that email")
          console.log(error.message)
        });
        console.log("hi")
      }

    return(
        <>
          {!emailSent? (
            <Container component="main" maxWidth="xs">
                <Paper style={{padding: 10}} elevation={5}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h4">
                    Forgot Password
                    </Typography>
                    <Typography align='center'>We will send you instructions on how to reset your password by email</Typography>
                    <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Typography style={{ color: 'red' }}>{error}</Typography>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick = {forgotPassword}
                    >
                        Reset Password
                    </Button>
                    </form>
                </div>
                </Paper>
                </Container>) 
            :(
                (<Container component="main" maxWidth="xs">
                <Paper style={{padding: 10}} elevation={5}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h4">
                    Thank you!
                    </Typography>
                    <Typography align='center'>Please check your email.</Typography>
                </div>
                </Paper>
                </Container>)
            )}
        </>
    )
}