import React from "react"
import { Typography } from "@material-ui/core"
import Settings from "./settings/Settings"

export default function Account(){
    return(
        <>
            <Settings/>
        </>
    )
} 