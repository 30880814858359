import React from 'react'
import { Typography, TextField, Button, Link as MLink} from '@material-ui/core'

import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { Link } from 'react-router-dom';

export default function ReferralCode(props) {
    const db = firebase.firestore()

    async function getURL(){
        console.log("hi",props.email)

        const user = await(db.collection("users").doc(props.email).get())
        console.log(user.data().affiliateCode)

        if(user.data().affilaiteCode == undefined){
            db.collection("billing").doc("affiliate_codes").set({count: 10},{merge: true}).then((docRef)=>{
                console.log("res",docRef)
            })
        }

        //db.collection("billing").doc("affiliate_codes")


    }

    getURL()

    const url = "https://www.fastingpal.com/r/" + String(props.userId)
    return (
        <div>
            {/* <a href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://localhost:3000/connect/affiliate&client_id=ca_GdDscxiCnR68EDKRJN0GoYrDAbfIW3xQ&state={STATE_VALUE}&suggested_capabilities[]=transfers">sign up for connect</a> */}
            <Typography style={{marginTop: 30}} align="center">
                Earn 30% of all payments for each person you get to join fasting pal:
            </Typography>
            <Typography align="center">to get started share your custom link below and you will </Typography>
            <Typography align="center">recieve credit for the users that sign up after using your link.</Typography>
            <Typography align="center">Note: By using Fasting Pal's Affiliate Programs, you agree to the program's <MLink><Link to="/affiliatepolicy" target="_blank">terms and conditions</Link></MLink>.</Typography>
            <TextField fullWidth value={url} style={{marginTop: 25}}></TextField>
            
        </div>
    )
}
 