import React, { createContext, useContext, useState } from 'react';

const CalorieContext = createContext();

export const useCalories = () => useContext(CalorieContext);

export const CalorieProvider = ({ children }) => {
  const [totalCalories, setTotalCalories] = useState(0);

  return (
    <CalorieContext.Provider value={{ totalCalories, setTotalCalories }}>
      {children}
    </CalorieContext.Provider>
  );
};