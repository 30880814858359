import React from 'react'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import { useState } from 'react';
import { Typography, Paper, Input, Button, TextField } from '@material-ui/core';
import { useStore } from "../../util/globalStore";

export default function AffiliateStats(props) {
    const [count , setCount] = useState(0)
    const [pending, setPending] = useState(0)
    const [unpaid, setUnpaid] = useState(0)
    const [paypalEmail, setPaypalEmail] = useState("")
    const [verifyPaypalEmail, setVerifyPaypalEmail] = useState("")
    const [error,setError] = useState("")
    const email = "ref@test.com"
    const rate = .3
    const user = useStore(state => state.user);
    const db = firebase.firestore()

    async function getReferralCount(){
        const uid = user.uid
        console.log(uid)
        
        //var data = await db.collection("referrals").doc("2020 4").collection(uid).get()

        //instead of using the email here i need to get the users refferral code and use that to get the earnings or just use the uid as the url :/
        const aff = await db.collection("affiliates").doc(uid).get()
        let pendingEarnings = 0
        let unpaidEarnings = 0

        console.log(aff.data())

        if(aff.data()!==undefined){

            if(aff.data().pendingTotal !== undefined){
                pendingEarnings = aff.data().pendingTotal
            }
    
            if(aff.data().unpaidTotal !== undefined){
                unpaidEarnings = aff.data().unpaidTotal
            }

        }
        
        

        //console.log(data.docs.length)
        //setCount(data.docs.length)
        setPending(pendingEarnings)
        setUnpaid(unpaidEarnings)
    }

    async function requestPayment(){
        //console.log(paypalEmail, " requested a payment")
        //console.log(verifyPaypalEmail, "check")
        const paypalEmail = document.getElementById("paypalEmail").value
        const verifyPaypalEmail = document.getElementById("verifyPaypalEmail").value

        if(paypalEmail !== verifyPaypalEmail){
            //emails do not match
            setError("Emails do not match")
        }else{
            if(paypalEmail !== ""){
                if(unpaid < 50){
                    setError("unpaid earnings are less than $50")
                }else{
                    setError("")
                    db.collection("billing").doc("payouts").collection("payment_requests").doc(user.email).set({paypalEmail: paypalEmail, amount: unpaid}, {merge:true})
                }
                
            }else{
                setError("paypal address is blank")
            }
        }
    }

    getReferralCount()

    return (
        <div>
                <Typography variant="h5">Earnings</Typography>
                {/* <Typography>Sign Ups: {count}</Typography> */}
                <Typography>Total Earnings: ${((pending+unpaid)/100)*rate}</Typography>
                <Typography>Pending Earnings*: ${(pending/100)*rate}</Typography>
                <Typography>Unpaid Earnings: ${(unpaid/100)*rate}</Typography>
                <br/>
                <Typography>*Pending earnings are not guaranteed, they are pending in the event of a refund. Once payments are out of the 14 day refund window they will be moved into the unpaid earnings category.</Typography>
                <br/>
                <Typography>Once your unpaid total has reached $50, you can request a payout via PayPal. To do this, please provide your PayPal address and then use the button below to request a payout.</Typography>
                <TextField style={{marginTop: 10}} id="paypalEmail" variant="outlined" placeholder="paypal email" ></TextField>
                <br/>
                <TextField style={{marginTop: 10}} id="verifyPaypalEmail" variant="outlined" placeholder="verify paypal email"></TextField>
                <br/>
                <Typography style={{marginLeft: 10}} color="error">{error}</Typography>
                <Button style={{margin: 10}} variant="contained" onClick={requestPayment}>request payment</Button>
        </div>
    )
}
