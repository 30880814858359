import React from 'react'
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { useState } from 'react';
import { Typography, Button, TextField} from '@material-ui/core';

export default function GoalPerWeek(props) {
    const [goalPerWeek, setGoalPerWeek] = useState(null)
    const db = firebase.firestore()
    const [change,setChange] = useState(false)
    const [unit,setUnit] = useState(null)
    const [goalError, setGoalError] = useState(null)
    const [newGoal,setNewGoal] = useState("")

    //This gets the goal per week for the current user. If they havent set one the default is 1
    db.collection("users").doc(props.email).get().then((result)=>{
        if(result.data().goalPerWeek){
            setGoalPerWeek(result.data().goalPerWeek)
            setUnit(result.data().unit)
        }else{
            setGoalPerWeek(1)
            setUnit(result.data().unit)
        }
    })

    //maybe get the history here too so I can use the required weight that is in the db
    async function getRecentRequiredWeight(){
        var requiredWeight = []
        //get history
        var requiredWeights = []
        await db.collection("users").doc(props.email).collection("weights").orderBy("Date", "desc").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                if(doc.data().requiredWeight!==undefined){
                    requiredWeights.push([doc.data().Date.toDate(),doc.data().requiredWeight])

                }
            });
        })
        if(requiredWeight.length!==0){
            return(requiredWeights[0][1])
        }
    }

    getRecentRequiredWeight()

    //adds the new goal per week setting to the database
    async function changeGoalPerWeek(newGoal){
        const result = await db.collection("users").doc(props.email).get()
        const currentDate = new Date()
        const requiredWeight = result.data().recentRequiredWeight

        //adjusts the variables associated with the current user
        db.collection("users").doc(props.email).set({
            goalPerWeek: newGoal, 
            recentGoalDate: new Date(), 
            recentGoalFastPeriod: result.data().mostRecentFastPeriod, //instead of this i could just use recent req weight
            recentGoalWeightAvg: result.data().mostRecentWeightAvg,    //instead of this i could just use recent req weight
            recentGoalRequiredWeight: requiredWeight
        },{merge: true})

        //adds a document to the goals collection
        db.collection("users").doc(props.email).collection("goals").doc(currentDate.toDateString()).set({
            goalPerWeek: newGoal, 
            goalDate: new Date(), 
            recentGoalFastPeriod: result.data().mostRecentFastPeriod,  //instead of this i could just use recent req weight
            requiredWeight: requiredWeight       //instead of this i could just use recent req weight
        })
        setGoalPerWeek(newGoal)
    }

    async function makeChange(){
        if(change){
            //const newGoal=document.getElementById("new-goal").value
            const goal = newGoal
            if(!isNaN(goal)){
                if(Number(goal) >= 0 && Number(goal) <= 3 ){
                    changeGoalPerWeek(goal)
                    setGoalError(null)
                }else{
                    //the user entered a goal above 3 tell them to enter a lower goal
                    //I should use a slider instead to let the user select the goal
                    setGoalError("Please enter a goal that is 3 or less")
                }
                
            }else{
                //the user didn't enter a number
                console.log('not a number')
            }
            setChange(false)
        }else{
            setChange(true)
        }
    }
    function handleChange(e){
        console.log(Number(e.target.value))
        setNewGoal(e.target.value)
    }
    if(change===true){
        return(
            <>
            <Typography style={{padding: 20}} variant="h5">{unit} to lose per week:
                <TextField id="new-goal" autoFocus={true} style={{width:40, marginLeft:10}} placeholder={String(goalPerWeek)} onChange={handleChange}></TextField>
                <Button style={{marginLeft: 10}} size="small" variant="outlined" color="primary" onClick={makeChange}>Save</Button>
            </Typography>
            </>
        )
    }else{
        return (
            <>
                <Typography style={{padding: 20}} variant="h6">{unit} to lose per week: {goalPerWeek}
                    <Button style={{marginLeft: 20}} size="small" color="primary" variant="outlined" onClick={makeChange}>Change</Button>
                </Typography>
                <Typography color="error">{goalError}</Typography>
            </>
        )
    }
}
