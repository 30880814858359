import React, {useState} from "react"
import axios from 'axios'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import SubscriptionEndDate from "./SubscriptionEndDate"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core"

export default function Unsubscribe(){
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [subscription, setSubscription] = useState({cancel_at_period_end: false})

    const [user, setUser] = useState(null)
    if (user){
        const email = user.email
    }
    
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            //user exists
            setUser(user)
        } else {
            // No user is signed in.
        }
    });

    async function updateSubscription(){
        const customerId = await getCustomerId()
        const x = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/u/' + customerId + "/" + user.email)
        setSubscription({cancel_at_period_end: x.data.cancel_at_period_end})
        return(x)
    }

    async function getCustomerId(){
        var db = firebase.firestore()
        //get user's customer_id from database
        var customer = await db.collection("stripe_customers").doc(user.email).get().then((DocumentSnapshot)=>{
            return(DocumentSnapshot.data())
        })

        if(customer.customer_id == null){
            //the user doesnt have a stripe account
            //I think I can fix this manually by deleting the user from firebase and having them sign up again with the same email.
            console.log("no customer")
        }
        return(customer.customer_id)
    }

    return (
        <div>
          <Button style={{marginTop: 20}}  variant="outlined" color="primary" onClick={handleClickOpen}>
            Unsubscribe
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {(!subscription.cancel_at_period_end)?
                (
                    <>
                    <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        You will lose access to your dashboard on <SubscriptionEndDate setSubscription={setSubscription}></SubscriptionEndDate> if you unsubscribe. 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={updateSubscription} color="primary" autoFocus>
                        Unsubscribe
                      </Button>
                    </DialogActions>
                    </>
                    )
            :  (<>
                <DialogTitle id="alert-dialog-title">{"Successfully Unsubscribed"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You will lose access to your dashboard on <SubscriptionEndDate setSubscription={setSubscription}></SubscriptionEndDate> 
                </DialogContentText>
                </DialogContent>
                    </>)
            }
          </Dialog>
        </div>
      );
}