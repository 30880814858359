import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

export default async function getHistory () {
    var db = firebase.firestore()
    var history = [[]]
    var user = firebase.auth().currentUser
    var userEmail = String(user.email)
    // eslint-disable-next-line
    var userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").get()
    .then(function(querySnapshot) {
        history.pop()
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            var tempDate = doc.data().Date.toDate()
            history.push([tempDate, doc.data().Weight])
        });
        if (history.length===0){
            history.push([])
        }
    })
    return(history)
}


