import React, {useState} from "react"
import { Typography, Container, Paper, Link } from "@material-ui/core"
import Unsubscribe from "../../payment/unsubscribe/Unsubscribe"
import GoalPerWeek from "./GoalPerWeek"
import firebase from "firebase/app"
import "firebase/auth"
import History from "../../data/History"
import ReferralCode from "../../referral/referralCode"
import ResetWeightHistory from "./ResetWeightHistory"
import Notify from "./Notify"
import Notifications from "./Notifications"
import EnableNotifications from "./notifications/EnableNotifications"
import AffiliateStats from "../../referral/AffiliateStats"
import ResetCalculations from "./ResetCalculations"
import SelfReflection from "../../data/SelfReflection"
import Calendar from "../../data/Calendar"
import SelfReflectionToggle from "./SelfReflectionToggle"

export default function Settings(){
    const [user,setUser] = useState(null)
    
    firebase.auth().onAuthStateChanged(async function(user) {
        if (user) {
            setUser(user)
            
        } else {
          console.log("user is null")
        }
      });

    

    if(user!=null){
        return(
        <>
            <Container >
                <Paper style={{padding:20}}>
                    <Typography align="center" variant="h4">Settings</Typography>
                    <GoalPerWeek email={user.email}/>
                    <SelfReflectionToggle userEmail={user.email}/>
                    <History userEmail={user.email}/>
                    
                    {/* <ResetWeightHistory userEmail ={user.email}/> */}
                    {/* <EnableNotifications email={user.email}/> */}
                    {/* <ReferralCode userId={user.uid}/>
                    <AffiliateStats userId={user.uid} email={user.email}/> */}
                    {/* <Notify/> */}
                    <Calendar/>
                    <Notifications email={user.email}/>
                    <ResetCalculations/>
                    <Unsubscribe/>
                </Paper>
                {/* <a href="https://connect.stripe.com/express/oauth/authorize?client_id=ca_GdDs13V0EUEeU7KK8Eue1AISJoubDrNV&state={STATE_VALUE}&suggested_capabilities[]=transfers&stripe_user[email]=user@example.com">test</a>
                <a href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://localhost:3000/connect/affiliate&client_id=ca_GdDs13V0EUEeU7KK8Eue1AISJoubDrNV&state={STATE_VALUE}&suggested_capabilities[]=transfers">test</a> */}
            </Container>
        </>
        )
    }else{
        return(
            <>
            </>
        )
    }
}