import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore";
import {Button, TextField, Container, Typography} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EnterWeight(){
    const classes = useStyles()
    const [weight, setWeight] = useState("")
    let browserHistory = useHistory()
    var db = firebase.firestore()
    var user = firebase.auth().currentUser
    if (user === null){
        browserHistory.push("/signin")
        return (<p>Sign In</p>)
    }
    var userEmail = String(user.email)
    var tempDate = new Date()
    var myFirebaseFirestoreTimestampFromDate = firebase.firestore.Timestamp.fromDate(new Date() );

    async function storeData (e) {
      if(e){
        e.preventDefault()
      } 
      var goal = 1
      const result = await db.collection("users").doc(userEmail).get()

      if(result.data().goalPerWeek!==undefined){
        goal = result.data().goalPerWeek
      }
      if(!isNaN(weight)){
        await db.collection("users").doc(userEmail).collection("weights").add({
            Date: myFirebaseFirestoreTimestampFromDate,
            Weight: Number(weight),
            Goal: goal
        })

        //browserHistory.push("/dashboard")
        if(result.data().selfReflectionEnabled === true){
          browserHistory.push("/selfreflection")
        }else{
          browserHistory.push("/dashboard")
        }
        
      }
    }
    return(
        <Container component="main" maxWidth="xs">
            <form className={classes.form} onSubmit={e => storeData(e)}>
                <div className = {classes.root, classes.paper}>
                  <Typography variant="h4">Enter Your Weight</Typography>
                  <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="weightField"
                  label="Enter Your Weight"
                  autoFocus
                  onChange={e => setWeight(e.target.value)}
                  />
                  <Button
                      id="submit"
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick = {storeData}
                      onSubmit = {storeData}
                  >Submit</Button>
                </div>
            </form>
        </Container>
    )
}