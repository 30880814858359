import React from 'react'
import {Container, Paper, Typography, List, ListItem, ListItemIcon} from "@material-ui/core"

export default function PrivacyPolicy() {
    return (
        <div>
            
            <Container maxWidth="md">
                <Paper style={{ padding: 20}} elevation="5">
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h3">Privacy Policy</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>This Privacy Policy governs the privacy policies and practices of our Website, located at FastingPal.com. Please read our Privacy Policy carefully as it will help you make informed decisions about sharing your personal information with us.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h4">Information We Collect</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>As a Visitor, you can browse our Website to find out more about our Website. You are not required to provide us with any personal information as a Visitor.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h4">Information You Provide to Us</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>We collect your personal information when you register with us ("User"), when you express an interest in obtaining information about us or our products and services, 
                        when you participate in activities on our Website Site (such as using our builder) or otherwise contacting us.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>Generally, you control the amount and type of information you provide to us when using our Website. 
                        The personal information that we collect depends on the context of your interaction with us and the Website, 
                        the choices you make and the products and features you use. The personal information we collect can include the following:</Typography>
                    <div style={{paddingTop:20}}>
                        <Typography style={{paddingLeft:20}}>
                            <List >
                                <ol style={{listStyle: 'disc'}}>
                                    <ListItem><li>Name, Email Address and Contact Data</li></ListItem>
                                    <ListItem><li>Payment Information. If you choose to buy one of our Policy, we collect payment information through our payment processor. All payment information is securely stored by our payment processor and we do not have access to your sensitive information, such as credit card number or security code.</li></ListItem>
                                    <ListItem><li>Business Information. If you choose to create a Policy on our Website, we may ask specific information related to your business in order to create the Policy.</li></ListItem>
                                </ol>
                            </List>
                        </Typography>
                    </div>
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h4">Automatically Collected Information</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>When you use our Website, we automatically collect certain computer information by the interaction of your mobile phone or web browser with our Website. Such information is typically considered non-personal information. We also collect the following:</Typography>

                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h5">Cookies</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>Our Website uses "Cookies" to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. We never place Personally Identifiable Information in Cookies.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h5">Log Information</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>We automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our Website, if any, as well as the name of the website to which you're headed when you leave our website. This information also includes the IP address of your computer/proxy server that you use to access the Internet, your Internet Website provider name, web browser type, type of mobile device, and computer operating system. We use all of this information to analyze trends among our Users to help improve our Website.</Typography>
                    
                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR)</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>For the purpose of this Privacy Policy, we are a Data Controller of your personal information.</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:</Typography>
                        <div style={{paddingTop:10}}></div>
                        <Typography style={{paddingLeft:40}}>
                            <List >
                                <ol style={{listStyle: 'disc'}}>
                                    <li>We need to perform a contract with you, such as when you use our services</li>  
                                    <li>You have given us permission to do so</li>  
                                    <li>The processing is in our legitimate interests and it's not overridden by your rights</li>   
                                    <li>For payment processing purposes</li>
                                    <li>To comply with the law</li>
                                </ol>
                            </List>
                        </Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography style={{paddingLeft:40}}>
                            <List >
                                <ol style={{listStyle: 'disc'}}>
                                    <li>The right to access, update or to delete the personal information we have on you</li>  
                                    <li>The right of rectification</li>  
                                    <li>The right to object</li>   
                                    <li>The right of restriction</li>
                                    <li>The right to data portability</li>
                                    <li>The right to withdraw consent</li>
                                </ol>
                            </List>
                        </Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>Please note that we may ask you to verify your identity before responding to such requests.</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</Typography>
                    </div>

                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">"Do Not Sell My Personal Information" Notice for California consumers under California Consumer Privacy Act (CCPA)</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>Under the CCPA, California consumers have the right to:</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography style={{paddingLeft:40}}>
                            <List >
                                <ol style={{listStyle: 'disc'}}>
                                    <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>  
                                    <li>Request that a business delete any personal data about the consumer that a business has collected.</li>  
                                    <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>   
                                </ol>
                            </List>
                        </Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</Typography>
                    </div>

                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">Service Providers</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>We employ third party companies and individuals to facilitate our Website ("Service Providers"), to provide our Website on our behalf, to perform Website-related services or to assist us in analyzing how our Website is used. These third-parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</Typography>
                    </div>

                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">Analytics</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy/</Typography>
                    </div>

                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">Payments processors</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>We provide paid products and/or services on our Website. In that case, we use third-party services for payment processing (e.g. payment processors).</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council.</Typography>
                    </div>

                    <div style={{paddingTop:20}}>
                        <Typography variant="h4">Contacting Us</Typography>
                        <div style={{paddingTop:20}}></div>
                        <Typography>If there are any questions regarding this privacy policy you may contact us at fastingpal@gmail.com.</Typography>
                    </div>
                    
                    <div style={{paddingTop:40}}></div>
                    <Typography variant="h4"></Typography>

                    <div style={{paddingTop:20}}></div>
                    <Typography></Typography>
                </Paper>
            </Container>
        </div>
    )
}




