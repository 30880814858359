import React from "react"
import { CircularProgress, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        margin: "auto",
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export default function Loading(){
    const classes = useStyles();

    return(
        <Box className={classes.root}>
            <CircularProgress size ="10%"/>
        </Box>
    )
}