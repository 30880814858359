import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore";
import '../../css/Calendar.css';

function SelfReflectionCalendar() {
    const [ratings, setRatings] = useState({});
  
    // Retrieve data from Firebase here and store it in the 'ratings' state variable
    useEffect(() => {
      async function fetchData() {
        const user = firebase.auth().currentUser;
        const ratingsRef = firebase.firestore().collection("users").doc(user.email).collection("self-reflection");
        const ratingsSnapshot = await ratingsRef.get();
        const fetchedRatings = {};
        ratingsSnapshot.forEach(doc => {
          fetchedRatings[doc.data().date.toDate().toDateString()] = doc.data().rating;
        });
        setRatings(fetchedRatings);
      }
      console.log("Fetching data...")
      fetchData();
    }, []);

    function getTileClass(dateString) {
        switch (ratings[dateString]) {
            case "4":
                return "excellent-tile";
            case "B":
                return "good-tile";
            case "C":
                return "average-tile";
            case "D":
                return "bad-tile";
            case "F":
                return "terrible-tile";
            default:
                return "no-rating-tile";
        }
    }

    // Render the calendar here with the ratings data from the 'ratings' state variable as the tileContent prop value for the Calendar component and make the calendar dates smaller and show up in the top left corner of the tile
    return (
        <Card>
            <Calendar
            // className={({ date, view }) => {
            //     if (view === "month") {
            //         const dateString = date.toDateString();
            //         if (dateString in ratings) {
            //           let rating = ratings[dateString];
            //           if (rating === "excellent") {
            //             return "excellent-tile";
            //           } else if (rating === "good") {
            //             return "good-tile";
            //           } else if (rating === "average") {
            //             return "average-tile";
            //           } else if (rating === "bad") {
            //             return "bad-tile";
            //           } else if (rating === "terrible") {
            //             return "terrible-tile";
            //           } else {
            //             return "no-rating-tile";
            //           }
            //         }
            //     } 
            // }}
            className="modern-calendar"
                // tileContent={({ date, view }) => {
                //     if (view === "month") {
                //         const dateString = date.toDateString();
                //         const rating = ratings[dateString];
                //         let tileColor = '#fff';
                //         if (rating === "4") {
                //             tileColor = 'green';
                //         } else if (rating === "4") {
                //             tileColor = '#ffff00';
                //         } else if (rating === "C") {
                //             tileColor = '#ffa500';
                //         } else if (rating === "D") {
                //             tileColor = '#ff0000';
                //         }
                //         return (
                //             <div style={{ background: tileColor, position: "relative", top: 0, left: 0 }}>
                //                 <div style={{ position: "relative", top: -5, left: -15 }}>{date.getDate()}</div>
                //                 {rating ? rating : "-"}
                //             </div>
                //         );
                //     }
                // }
                // }
                tileContent={({ date, view }) => {
                    if (view === "month") {
                      const dateString = date.toDateString();
                      let className;
                      if (dateString in ratings) {
                        if (ratings[dateString] === "4") {
                          className = "rating-a";
                        } else if (ratings[dateString] === "B") {
                          className = "rating-b";
                        } // etc. for other ratings
                        return (
                          <div
                            className={`excellent-tile ${getTileClass(
                              dateString
                            )}`}
                          >
                            <div className="react-calendar__month-view__days__day">
                              <div
                                style={{
                                  position: "relative",
                                  top: -5,
                                  left: -15,
                                }}
                              >
                                {date.getDate()}
                              </div>
                              <div style={{ position: "relative", top: -5 }}>
                                {" "}
                                {ratings[dateString]}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return(
                            <div className="react-calendar__month-view__days__day">
                                <div
                                style={{
                                  position: "relative",
                                  top: -5,
                                  left: -15,
                                }}
                              >
                                {date.getDate()}
                              </div>
                                <div className="no-rating-title rating-a">-</div>
                                </div>
                        )
                      }
                    }
                  }}

                formatDay={(locale, date) => {
                    return(
                        <div className="react-calendar__month-view__days__day">
                            <div className="no-rating-title"></div>
                        </div>
                    )
                }}

                
            />
        </Card>
    );
  }



export default SelfReflectionCalendar;