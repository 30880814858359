// import Stripe from "stripe"
import React from 'react'
import axios from 'axios'
import {useHistory} from "react-router-dom"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { useState, useEffect } from 'react'
import Loading from "../layout/Loading"
import {Typography,Container, Paper } from "@material-ui/core";

// var stripe = Stripe('pk_test_m3UpqK8086Lam3NJpwrsXHjg00OoDEnHOa')

export default function SubscriptionStatus(props){
    const [sub, setSub] = useState(false)    
    const [subChecked, setSubChecked] = useState(false)
    let browserHistory = useHistory()
    //const [subscribed, setSubscribed] = useState(true)
    //var isSubscribed = await getIsSubscribed(userEmail)
    var isSubscribed = true
    useEffect(()=>{

        firebase.auth().onAuthStateChanged(async function(user) {
            if (user) {
                // User is signed in.
                // Get users customerId using the email from the firebase user
                var customerId = await getCustomerId(user.email)

                console.log(user.email)

                //call function with get requiest passing user id in path
                var x = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/verifySubscription/' + customerId+"/"+user.email)
                .then(async response => {
                    console.log(response)
                    isSubscribed = await checkSubscriptionStatus(response)
                    console.log("1",isSubscribed)
                    return(isSubscribed)
                    //setIsSubscribed(isSubscribed)
                })
                console.log("x:",x)
                return(x)

            } else {
            // No user is signed in.
            // Send them to the sign in page
            browserHistory.push("/signin")
            console.log("user is null")
            }
        });

        async function getCustomerId(email){
            console.log(email)
            var db = firebase.firestore()
            //get user's customer_id from database
            var customer = await db.collection("stripe_customers").doc(email).get().then((DocumentSnapshot)=>{
                return(DocumentSnapshot.data())
            })
            if(customer !== undefined && customer.customer_id == null){
                //the user doesnt have a stripe account
                //I think I can fix this manually by deleting the user from firebase and having them sign up again with the same email.
            }
            return(customer.customer_id)
        }

        function dateDiffInDays(a, b) {
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        }

        async function checkSubscriptionStatus(customer){
            if (customer.data.subscriptions.data[0]== null){
                //this if statement happens when a user made an account but never 
                //subscribed so they have no subs on their stripe account
                console.log("test1")
                setSubChecked(true)
                return(isSubscribed)
            }
            const subscription = customer.data.subscriptions.data[0]
            const endPeriod = subscription.current_period_end
            const endPeriodDate = new Date(endPeriod*1000)
            const currentDate = new Date()
            const daysTillPeriodEnd = dateDiffInDays(currentDate,endPeriodDate)
            console.log(isSubscribed)
            if(daysTillPeriodEnd >=0){
                //sets the subscription status to true if the current date is less than the end of sub date
                setSub(true)
                
                //browserHistory.push("/renew")
            }
            setSubChecked(true)
            console.log("hello",isSubscribed)
            return(isSubscribed)
        }

    },[])

    return(
    <div>
        {(subChecked)?
            <div>{props.children}{props.setIsSubscribed(sub)}
        </div>:<Loading/>}
    </div>
    )
}
