import React, {useState} from "react"
import axios from "axios"
import { Grid, TextField, Button, Typography } from "@material-ui/core"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

export default function Coupon(props){
    const [user,setUser] = useState(null)
    const [coupon, setCoupon] = useState(null)

    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            console.log('This is the user: ', user)
            setUser(user)
        } else {
            // No user is signed in.
            console.log('There is no logged in user');
        }
    });
    
    async function addCoupon(){
        console.log(user.email)
        var db = firebase.firestore()
        const testCoupon = "XmTADwsB"
        const coupon = document.getElementById("coupon").value
        const customerId = await getCustomerId(user.email)
        var x = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/getCoupon/' + user.email + "/" + customerId + '/' + coupon)
                .then(async response => {
                    console.log(response)
                    if(response.data.object==="coupon"){
                        await db.collection("stripe_customers").doc(user.email).collection("coupons").add({coupon: response.data , date: Date()})
                    }
                    setCoupon(response)
                    //setIsSubscribed(isSubscribed)
                })
        //console.log("hi")
        return("hi")
    }

    async function getCustomerId(email){
        if(email === null){
            //user is null
            console.log("user is null")
        }
        var db = firebase.firestore()

        //get user's customer_id from database
        var customer = await db.collection("stripe_customers").doc(email).get().then((DocumentSnapshot)=>{
            return(DocumentSnapshot.data())
        })

        if(customer.customer_id == null){
            //the user doesnt have a stripe account
            //I think I can fix this manually by deleting the user from firebase and having them sign up again with the same email.
        }
        return(customer.customer_id)
    }

    return(
        <Grid container style={{margin: "auto"}}>
            <Grid item xs={6}>
                <TextField id="coupon" label="coupon code" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <Button size="small" variant="outlined" style={{marginTop: 17, marginLeft:10}} onClick={addCoupon}>Add Coupon</Button>
            </Grid>
            {coupon?
                ((coupon.data.object==="coupon")?
            <Typography style={{marginTop: 10}} color="error">Coupon Applied: {coupon.data.percent_off}% OFF for {coupon.data.duration_in_months} {coupon.data.duration_in_months>1?"months":"month"}</Typography>
                    :
                    <Typography color="error">Invalid Coupon</Typography>)
                :
                (null)
            }
        </Grid>
    )
}