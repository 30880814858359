import React from 'react';
import { useEffect, useState} from 'react';

import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"

import ApexChart from "./ApexChart"
import { Button } from '@material-ui/core';

import { Card, CardContent, Typography } from '@material-ui/core';



export default function Graph(props) {

    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)
    const [timeFrame, setTimeFrameState] = useState(localStorage.getItem("timeFrame") || "all");
    const [req,setReq] = useState(null)
    var requiredWeights =[]
    var db = firebase.firestore()

    function setTimeFrame(newTimeFrame) {
        setTimeFrameState(newTimeFrame);
        localStorage.setItem("timeFrame", newTimeFrame);
    }

    async function getHistory (timeFrame) {
        var history = []
        var userEmail = props.userEmail
        var userHistory;
        if(timeFrame === "last 30 days"){
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
            userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").where("Date", ">", thirtyDaysAgo).get()
        }else if(timeFrame === "last 90 days"){
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
            userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").where("Date", ">", ninetyDaysAgo).get()
        }else{
            userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").get()
        }
        userHistory.forEach(function(doc) {
            var tempDate = doc.data().Date.toDate()
            history.push([tempDate, doc.data().Weight])
        });
        return(history)
    }

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
    async function getGoals(timeFrame) {
        var requiredWeights = []
        var userEmail = props.userEmail
        var userHistory;
        if(timeFrame === "last 30 days"){
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
            userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").where("Date", ">", thirtyDaysAgo).get()
        }else if(timeFrame === "last 90 days"){
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
            userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").where("Date", ">", ninetyDaysAgo).get()
        }else{userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").get()
    }
    userHistory.forEach(function(doc) {
    if(doc.data().requiredWeight!==undefined){
    requiredWeights.push([doc.data().Date.toDate(),doc.data().requiredWeight])
    }
    });
    const user = await db.collection("users").doc(userEmail).get()

    if(user.data().startDate!==undefined){
        if(timeFrame === "all" || user.data().startDate.toDate() > thirtyDaysAgo || user.data().startDate.toDate() > ninetyDaysAgo){
            requiredWeights.reverse()
            requiredWeights.unshift([user.data().startDate.toDate(), user.data().startWeight + props.offset])
            requiredWeights.push([user.data().recentDate.toDate(), user.data().recentRequiredWeight])
        }
        
    }
    async function roundArray(array){
        var roundedArray = []
        for(var i = 0; i<array.length; i++){
          array[i][1] = Math.round(array[i][1]*10)/10
        }
        return(roundedArray)
      }
    roundArray(requiredWeights)
    return(requiredWeights)
}


useEffect(() => {
    setLoading(true);
    async function prepHistory(){
        console.log("timeFrame changed to:", timeFrame);
        setHistory(await getHistory(timeFrame))
        setReq(await getGoals(timeFrame))
        setLoading(false)
    }
    prepHistory()

}, [timeFrame])
    return (
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          // <>

          //     <select name="timeFrame" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          //         <option value="all">All</option>
          //         <option value="last 30 days">Last 30 days</option>
          //         <option value="last 90 days">Last 90 days</option>
          //     </select>
          // <ApexChart offset={props.offset} userEmail ={props.userEmail} history={history} fastPeriod={props.fastPeriod} requiredWeights={req} timeFrame={timeFrame}/>
          // </>

          <Card style={{ margin: "20px" }}>
            <CardContent>
              <select
                name="timeFrame"
                value={timeFrame}
                onChange={(e) => setTimeFrame(e.target.value)}
              >
                <option value="all">All</option>
                <option value="last 30 days">Last 30 days</option>
                <option value="last 90 days">Last 90 days</option>
              </select>
              <ApexChart
                offset={props.offset}
                userEmail={props.userEmail}
                history={history}
                fastPeriod={props.fastPeriod}
                requiredWeights={req}
              />
            </CardContent>
          </Card>
        )}
      </div>
    );
}