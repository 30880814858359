import React from 'react'
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { useStore } from "../../util/globalStore";
export default function Calendar() {
    const db = firebase.firestore()
    const user = useStore(state => state.user);

    //get current user info

    //get the grades of the current user
    async function getGrades(){
        const grades =await db.collection("users").doc(user.email).collection("grades").get()
        grades.docs.forEach((doc)=>{console.log(doc.data())})
    }
    getGrades()

    return (
        <div>


            
        </div>
    )
}
