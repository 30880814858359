import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from "firebase/app";
import "firebase/auth";
import getHistory from "../data/GetHistory"
import {useStore} from "../../util/globalStore"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MLink color="inherit" href="https://material-ui.com/">
        Your Website
      </MLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  let browserHistory = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [history, setHistory] = useState(null)
  const setNewHistory = useStore(state => state.setHistory);

  useEffect(() => {
    setNewHistory(history);
  }, [history])

  async function signedIn() {
    var errorCode = "";
    var errorMessage = "";
    var isError = false;
    
    await firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
        // Handle Errors here.
        errorCode = error.code;
        errorMessage = error.message;
        isError = true;
        // ...
        console.log("error code: " + errorCode)
        console.log("error message: " + errorMessage)
        const userErrorMessage = "Incorrect password or username"
        setError(userErrorMessage)
      })

      if (!isError) {
        var history = [[0,0]]
        history = await getHistory()
        setHistory(history)
        
        var mostRecentDate = new Date(history[0][0])
        var currentDate = new Date()
        var historyDays = Math.floor(mostRecentDate/(60*60*1000))
        var currentDays = Math.floor((currentDate)/(60*60*1000))

        if (history[0].length!=0 && (currentDate.getFullYear()===history[0][0].getFullYear())&&(currentDate.getMonth()===history[0][0].getMonth())&&(currentDate.getDate()===history[0][0].getDate())){
          browserHistory.push("/Dashboard")
        }else{
          //console.log(historyDays, currentDays,currentDate.getTimezoneOffset(), mostRecentDate.getTimezoneOffset())
          browserHistory.push("/Dashboard")
        }
      }
    }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Typography style={{ color: 'red' }}>{error}</Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick = {signedIn}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link path to="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link path to="/signup">
                  {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}