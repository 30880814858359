import React from 'react';
import {Elements} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';
//import stripe from "stripe"
//const stripePromise = loadStripe("pk_test_m3UpqK8086Lam3NJpwrsXHjg00OoDEnHOa");
const stripePromise = loadStripe("pk_live_qeS6Xwl6tkIdgNSbFdyb6VXO009eNOxIhk");

const MyStoreCheckout = () => {
  
    // stripe.createPaymentMethod({
    //     type: 'card',
    //     card: InjectedCheckoutForm,
    //     billing_details: {
    //       email: 'jenny.rosen@example.com',
    //     },
    //   }).then(function(result) {
    //     // Handle result.error or result.paymentMethod
    //     console.log(result)
    //   });
    //console.log(stripe.SubscriptionsResource)
    
  return (
    <Elements stripe={stripePromise}>
        <CheckoutForm/>
    </Elements>
  );
};

export default MyStoreCheckout;