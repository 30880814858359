import React, {useContext} from 'react'
import { Typography, Paper, Container, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Button } from '@material-ui/core'
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import {useHistory} from "react-router-dom"
import { AuthContext } from "../../auth/Auth"
import Notifications from './Notifications';
import {useStore} from '../../../util/globalStore'
import ResetCalculations from './ResetCalculations';

export default function SignUpSettings() {
    const [value, setValue] = React.useState(null);
    const [showError, setShowError] = React.useState(false)
    const {currentUser} = useContext(AuthContext)
    let browserHistory = useHistory()

    const handleChange = event => {
        setValue(event.target.value);
        setShowError(false)
    };

    function handleClick(){
        //show error if value is null
        if(value===null){
            setShowError(true)
        }
        //save setting to firebase
        if(value!==null){
            firebase.firestore().collection("users").doc(currentUser.email).set({unit: value},{merge: true})
            browserHistory.push("/dashboard")
        }
    }

    async function checkUnit(){
        const unit = (await firebase.firestore().collection("users").doc(currentUser.email).get()).data().unit
        console.log(unit)
        if(unit !== undefined){
            browserHistory.push("/dashboard")
        }
    }

    if(currentUser){
        checkUnit()
    }
    

    return (
        <div>
        {(currentUser)? 
        <Container maxWidth="sm">
        <Typography variant="h5">Settings</Typography>
        <Paper style={{padding: 10}} elevation={5}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Units</FormLabel>
                <RadioGroup color="primary" aria-label="gender" name="gender1" value={value} onChange={handleChange} >
                    <FormControlLabel  value="lbs" control={<Radio color="primary" />} label="lbs"/>
                    <FormControlLabel value="kg" control={<Radio color="primary" />} label="kg" />
                </RadioGroup>
                <Typography>You can recieve text notifications to remind you to enter your weight, when your fast is starting and when your fast is ending. Your notifications can also be found on the settings page.</Typography>
                <Notifications email={currentUser.email}/>
                {showError?<Typography color="error">You must select an option for units</Typography>:(null)}
                <Button style={{marginTop: "15px"}} onClick={handleClick} variant="outlined">Submit</Button>
            </FormControl>
        </Paper>
        </Container> : null}
        </div>
    )
}
