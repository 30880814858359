import React from 'react'
import AffiliateStats from "./AffiliateStats"
import { useStore } from "../../util/globalStore";
import ReferralCode from './referralCode';
import { Paper, Typography, Container } from '@material-ui/core';
import SignIn from '../auth/SignIn';


export default function Affiliate() {
    const user = useStore(state => state.user);

    return (
        <div>
            {user?
            <>
            <Container maxWidth="md">
            <Paper style={{padding: 10, margin: 20}} elevation={3}>
                <Typography variant="h3" align="center">Affiliate Program</Typography>
                <ReferralCode userId={user.uid} email={user.email}/>
                <br/>
                <AffiliateStats/>
            </Paper>
            </Container>
            </>
            :null}
        </div>
    )
}
