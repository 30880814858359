import React from "react"
import monitor from "../icons/monitor3.png"
import intermittentFasting from "../icons/intermittent.png"
import { Container, Typography,  Paper , Grid, Box, Hidden} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import firebase from "firebase/app"
import "firebase/firestore"
import Cookies from "universal-cookie"
import YouTube from 'react-youtube-embed';

//https://github.com/kevinsqi/react-circular-progressbar
//This link is the progress bar I would like to use for the fasting timer. 
const useStyles = makeStyles(theme => ({
    root: {
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginBottom: 15,
        textAlign: "center",
    },
    desc: {
        flexGrow: 1,
        marginBottom: 15,
        textAlign: "center",
    },
    paper:{
        padding: 15,
    },
    marginAutoItem: {
        margin: 'auto'
      },
  }));

export default function HomePage() {
    let {ref} = useParams()
    //console.log("ref",ref)
    const cookies = new Cookies();

    if(ref!==undefined){
        //store ref code in cookie
        var experationDate = new Date()
        const time = experationDate.getTime() + 3600*1000*48
        experationDate.setTime(time)
        document.cookie = `ref=${ref}; expires=${experationDate.toUTCString()}; path=/;`;
        //cookies.set('ref', ref, { path: '/' });
        //console.log("cookie:",cookies.get('ref'));
    }
    //console.log("cookie:",cookies.get('ref'));

    const classes = useStyles()

    return(
        <Container maxWidth="lg">
            <Paper className={classes.paper} elevation={3}>
                <div>
                    <Typography  className={classes.title} variant="h4">Welcome to Fasting Pal!</Typography>
                    <Grid container >
                        <Grid container justify="center" >
                            <Grid xs={12} sm={12} md={6}>
                                <YouTube id="DZrS6xKrbNg" />
                            </Grid>
                                {/* <ReactPlayer url="https://www.youtube.com/watch?v=J6rVaFzOEP8"/> */}
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} sm={12} md={6} height="100%">
                                <Box
                                    display="flex" 
                                    height="100%" 
                                >
                                    <Box className={classes.marginAutoItem}>
                                        <Typography style={{}} className={classes.title} variant="h4" >What is Fasting Pal?</Typography>
                                        <Typography style={{margin: 15,fontSize: 19}} className={classes.desc} variant="body1">
                                            Fasting Pal is an app that helps you obtain your weight loss goals by adapting to your progress.
                                            The app accomplishes this by utilizing concepts that are also found in intermittent fasting.
                                        </Typography>
                                    </Box>
                                </Box>   
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={6 } style={{marginTop: 20}}>
                            <img src={monitor} width="100%"/>
                        </Grid>
                        <Hidden mdUp>
                        <Grid item xs={12} sm={12} md={6} height="100%">
                            <Box
                                display="flex" 
                                height="100%" 
                            >
                                <Box className={classes.marginAutoItem}>
                                    <Typography style={{}} className={classes.title} variant="h4" >What is Fasting Pal?</Typography>
                                    <Typography style={{margin: 15,fontSize: 19}} className={classes.desc} variant="body1">
                                        Fasting Pal is an app that helps you obtain your weight loss goals by adapting to your progress.
                                        The app accomplishes this by utilizing concepts that are also found in intermittent fasting.
                                    </Typography>
                                </Box>
                            </Box>     
                        </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={6 } style={{marginTop: 40}}>
                            <img src={intermittentFasting} width="70%" style={{marginLeft: "15%"}}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} height="100%">
                            <Box
                                display="flex" 
                                height="100%" 
                            >
                                <Box className={classes.marginAutoItem}>
                                    <Typography style={{marginTop: 40}} className={classes.title} variant="h4" >What is Intermittent Fasting?</Typography>
                                    <Typography className={classes.desc} style={{margin: 15,fontSize: 19}} variant="body1">
                                        Intermittent Fasting (IF) is an eating pattern where you switch between periods of eating and fasting. 
                                        You are still able to eat a normal amount of food but just in a smaller time frame, which is called your 'eating window.' 
                                        It is a healthy way towards your weight loss.
                                    </Typography>
                                </Box>
                            </Box>   
                        </Grid>
                        <Grid style={{width: "100%",margin: "5%"}}>
                            <Typography  style={{marginTop: 60}} className={classes.title} variant="h4">
                                What makes Fasting Pal different from other fasting apps?
                            </Typography>
                            <Typography className={classes.desc} style={{margin: 15,fontSize: 19}} variant="body1" >
                                Fasting Pal is the only app that adjusts your eating window based on your progress. 
                                It does this by asking the user to enter their weight every morning.
                                Then it uses this weight to calculate what your eating window should be for the day.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Container>
    )
}