import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import firebase from "firebase/app";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

function SelfReflectionToggle({ userEmail }) {
  const [selfReflectionEnabled, setSelfReflectionEnabled] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const userRef = firebase.firestore().collection("users").doc(userEmail);
    userRef.get().then(doc => {
      setSelfReflectionEnabled(doc.data().selfReflectionEnabled);
    });
  }, [userEmail]);

  const handleChange = (event) => {
    setSelfReflectionEnabled(event.target.checked);
    const userRef = firebase.firestore().collection("users").doc(userEmail);
    userRef.update({ selfReflectionEnabled: event.target.checked });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={selfReflectionEnabled}
          onChange={handleChange}
          color="primary"
        />
      }
      label="Self-Reflection"
    />
  );
}

export default SelfReflectionToggle;