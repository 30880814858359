import React from 'react'
import { Radio, FormControlLabel, Typography, Button, Container, Paper } from '@material-ui/core'

import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { useStore } from "../../util/globalStore";

import {useHistory} from "react-router-dom"

export default function SelfReflection() {
    const db = firebase.firestore()
    const user = useStore(state => state.user);

    let browserHistory = useHistory()

    const [selectedValue, setSelectedValue] = React.useState('');
    const [error, setError] = React.useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    function handleClick(){
        const currentDate = new Date()

        if(selectedValue!==''){
            const currentDate = new Date();
const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
            //Store selection in the users database for today.
            db.collection("users").doc(user.email).collection("self-reflection").doc(previousDate.toDateString()).set({rating: selectedValue, date: previousDate})
            //attach the grade to the most recent weight entered

            //send the user to the dashboard
            browserHistory.push("/dashboard")
        }else{
            //the user didn't make a selection
            setError("Please select a value")
        }

    }

    //Ask the user how they did the day before
    return (
        <div>
            <Container maxWidth= "xs" style={{marginTop: 50}}>
                <Paper>
                <Typography variant="h4" align="center">Self Reflection</Typography>
                <Typography align="center">How well did you follow the app yesterday?</Typography>
                <br/>
                <FormControlLabel
                    value="A"
                    control={<Radio
                        checked={selectedValue === 'A'}
                        title = "A"
                        onChange={handleChange}
                        value="A"
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />}
                    label="A"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value="B"
                    control={<Radio
                        checked={selectedValue === 'B'}
                        title = "B"
                        onChange={handleChange}
                        value="B"
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'B' }}
                    />}
                    label="B"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value="C"
                    control={<Radio
                        checked={selectedValue === 'C'}
                        title = "C"
                        onChange={handleChange}
                        value="C"
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'C' }}
                    />}
                    label="C"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value="D"
                    control={<Radio
                        checked={selectedValue === 'D'}
                        title = "D"
                        onChange={handleChange}
                        value="D"
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'D' }}
                    />}
                    label="D"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value="E"
                    control={<Radio
                        checked={selectedValue === 'E'}
                        title = "E"
                        onChange={handleChange}
                        value="E"
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'E' }}
                    />}
                    label="E"
                    labelPlacement="top"
                />
                <br/>
                <br/>
                <Typography color="error">{error}</Typography>
                <Button onClick={handleClick} fullWidth style={{padding:20}} variant="outlined">Submit</Button>
                </Paper>
            </Container>
           
    </div>
    )
}
