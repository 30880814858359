import React from "react"
import {Container, Paper, Typography } from "@material-ui/core"

export default function Contact(){
    return(
        <Container maxWidth="xs">
            <Paper style={{padding: 40}} elevation="6">
                <Typography variant="h3" align="center">Contact us</Typography>
                <Typography align="center">If you have any trouble using the app or if you have any reccomendations please email us at <a href="mailto:fastingpal@gmail.com">fastingpal@gmail.com</a></Typography>
            </Paper>
        </Container>
    )
}