import React, {useState} from "react"
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import { Container, Paper, Button, Typography } from "@material-ui/core"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import Loading from "../layout/Loading"

export default function NoCardNeeded(){
    const browserHistory = useHistory()
    const [user,setUser] = useState()
    const [loading, setLoading] = useState(false)
    const [trialUsed, setTrialUsed] = useState(false)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function fakeLoading(){
        await sleep(10000)
        browserHistory.push("/dashboard")
    }
    // if(loading===true){
    //   fakeLoading()
    // }

    firebase.auth().onAuthStateChanged(async function(user) {
        if (user) {
            setUser(user)
        } else {
          console.log("user is null")
        }
      });

    async function freeTrial(){
        const email = user.email
        const data = (await firebase.firestore().collection("users").doc(email).get()).data()
        const customerData = (await firebase.firestore().collection('stripe_customers').doc(email).get()).data()

        if(data.trialUsed === undefined){
            console.log(email)
            setLoading(true)
            if(customerData.customer_id === undefined){
                console.log("no cust id")
                return(freeTrial())
            }else{
                console.log("cust id found")
            }
            var x = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/freeTrial/' + email)
            browserHistory.push("/dashboard")
            //setLoading(false)
            console.log(x)
        }else{
            //the user has already used the trial
            setTrialUsed(true)
        }
    }

    return(
        <>
        {loading?(
                <>
                <Paper style={{padding: 10, marginTop: 20}} elevation={5}>
                    <Typography align="center" variant="h4">Processing Trial</Typography>
                    <Loading/>
                </Paper>
                </>
            ):(
                <Paper style={{padding: 10, marginTop: 20}} elevation={5}>
                    <Typography>        
                        Try it out for free for 2 weeks with no card required.</Typography>
                    <Button  style={{marginTop: 20}}color="primary" variant="contained" type="submit" fullWidth={true} onClick={freeTrial}>2 Week Trial</Button>
                    {trialUsed?<Typography color="error">Free trial is only allowed once per user</Typography>: null}
                </Paper>
        )}  
        </>
    )
}