/*
Thanks for purchasing our products (or subscribing to our services) at [website] operated by [name].

We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.

After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs.

If you have any additional questions or would like to request a refund, feel free to contact us.
*/

import React from 'react'
import { Typography, Paper, Container } from '@material-ui/core'

export default function RefundPolicy() {
    return (
        <div>
            <Container maxWidth="md">
                <Paper style={{textAlign: "center", padding: 20}} elevation="5">
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h3">Refund Policy</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>Thanks for subscribing to our services at fastingpal.com operated by John Sorrentino.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the app, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the service in the first two weeks after their purchase to ensure it fits your needs.</Typography>
                    <div style={{paddingTop:20}}></div>
                    <Typography>If you have any additional questions or would like to request a refund, feel free to contact us.</Typography>
                    <div style={{paddingTop:40}}></div>
                </Paper>
            </Container>
        </div>
    )
}
