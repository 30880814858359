import React from 'react'
import { Typography, Paper, Container } from '@material-ui/core'

export default function AffiliatePolicy() {
    return (
        <div>
            <Container maxWidth="md">
                <Paper style={{textAlign: "left", padding: 20}} elevation="5">
                    <div style={{paddingTop:20}}></div>
                    <Typography variant="h3">Fasting Pal Affiliate/Partner Programs</Typography>
                    <Typography>
                    <br/>
                    <Typography variant="h4">Terms and Conditions</Typography>
                    <br/>
                    <ol>
                    <Typography variant="h5">Affiliate Partner Program</Typography>
                    <br/>
                    <li> Affiliate partners of Fasting Pal receive a recurring commission for referring new customers to Fasting Pal using their unique referral link.</li>
                    <li> Affiliate partners are able to manage their referrals and request payouts for their earned commission using Fasting Pal&rsquo;s partner portal.</li>
                    </ol>
                    <br/>
                    <p>By using any of Fasting Pal&rsquo;s partner programs, you are agreeing that you have read and understand the terms and conditions of this agreement and that you agree to be legally responsible for each and every term and condition.</p>
                    <ol>
                    <br/>
                    <Typography variant="h5">Partner Websites</Typography>
                    <br/>
                    </ol>
                    <p>Partners may not create or design their website or any other website that they operate, explicitly or implied in a manner which resembles Fasting Pal websites, nor shall they design their own website in a manner which leads customers to believe they are Fasting Pal or any other affiliated business. Expert Partners and Reseller Partners may embed their official partner badges on their website and state their partner status on their website.</p>
                    <br/>
                    <p>Partners may not give the impression of being employed by Fasting Pal on their resumes both online and offline, on LinkedIn, or other social networking sites.</p>
                    <br/>
                    <p>Fasting Pal reserves the right to reject Partner applications or cancel an existing Partner account if we determine that their site is unsuitable for our Program, including, but not limited, if the site:</p>
                    <br/>
                    <p>1.1.1. Promotes sexually explicit materials.</p>
                    <p>1.1.2. Promotes violence.</p>
                    <p>1.1.3. Promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</p>
                    <p>1.1.4. Promotes illegal activities.</p>
                    <p>1.1.5. Incorporates any materials which infringe or assist others to infringe on any copyright, trademark or other intellectual property rights or to violate the law.</p>
                    <p>1.1.6. Includes &ldquo;Fasting Pal&rdquo; or variations or misspellings thereof in its domain name.</p>
                    <p>1.1.7. Is otherwise in any way unlawful, harmful, threatening, defamatory, obscene, harassing, or racially, ethnically or otherwise objectionable to us in our sole discretion.</p>
                    <p>1.1.8. Contains software downloads that potentially enable diversions of commission from other Affiliates in our program.</p>
                    <p>1.1.9. Promotes &ldquo;get-rich-quick&rdquo; schemes that have no tangible business value.</p>
                    <p>1.1.10. Is in violation of any other term or condition referenced herein.</p>
                    <ol start="2">
                    <br/>
                    <Typography variant="h5">Advertising and Promotions</Typography>
                    </ol>
                    <br/>
                    <p>Unless otherwise approved in writing by Fasting Pal, Affiliates may not offer incentives to users as a means to enhance the performance of any program; incentives include but are not limited to awarding them cash, points, prizes, contest entries, etc.</p>
                    <ol start="3">
                    <br/>
                    <Typography variant="h5">Payments of Commissions</Typography>
                    </ol>
                    <br/>
                    <p>Affiliate Partners receive a 30% recurring commission for paid signups of new users they refer to Fasting Pal products using their unique Affiliate link. In order to be tracked as a referral, the signup must occur within 30 days after the visitor has clicked on the Affiliate&rsquo;s link. If a visitor clicks on two different Affiliate links before signing up, only the owner of the most recent Affiliate link will receive a commission.</p>
                    <br/>
                    <p>The Affiliate Partner Commissions are recurring &ndash; the amount is subject to change as customers may switch subscription plans and add or remove licenses over the period of their subscription. For instance, if the referred customer upgrades to a higher plan or adds additional licenses to their team after their initial sign-up, the partner&rsquo;s commission increases accordingly.&nbsp;</p>
                    <br/>
                    <p>Payments of commissions are always made in USD. If the Partner is based in the EU, their payment will be converted to Euros on the day that Fasting Pal makes the payment. Partners can track their referrals and commissions in Fasting Pal&rsquo;s Partner Portal and may request a payout of their commission as soon as an accumulated commission of $50 USD has been reached. Payouts are exclusively handled via PayPal.</p>
                    <br/>
                    <p>Affiliate Partners and Expert Partners are guaranteed the commission of a referred user&rsquo;s first payment. Fasting Pal intends to keep the recurring commission model indefinitely but reserves the right to change and/or discontinue the recurring commission model at any time. Fasting Pal promises to inform Partners of such changes with at least one month&rsquo;s notice.</p>
                    <br/>
                    <p>Fasting Pal reserves the right to discontinue any partnerships and their associated commissions, one or all Partner levels or the Partner Program as a whole at any time and without any reason given.</p>
                    <br/>
                    <p>Affiliate Partners and other Partners of Fasting Pal are subject to Fasting Pal&rsquo;s terms of service.</p>
                    </Typography>
                </Paper>
            </Container>
        </div>
    )
}
