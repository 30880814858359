import React, { useState } from 'react'
import { MuiPickersUtilsProvider,TimePicker as TP } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import firebase from "firebase";
import "firebase/firestore"

export default function TimePicker(props) {
    const [loading,setLoading] = useState(true)
    const [oldArray,setOldArray] = useState(null)
    const [date,setDate] = useState(null)
    const db = firebase.firestore()

    if(oldArray == null){
        init()
    }

    async function init(){
        const data = (await db.collection("users").doc(props.email).get()).data()
        setLoading(false)
        console.log(data.notificationTimes)
        setDate(data.notificationTimes[0].toDate())
        setOldArray(data.notificationTimes)
        return(data)
    }

    async function handleChange(e){
        //save change for notification to the database
        oldArray[0] = e
        db.collection("users").doc(props.email).set({notificationTimes: oldArray},{merge: true})

        //display the changed date to the user
        setDate(e)
    }

    return loading? <div></div> : (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <TP value={date} onChange={handleChange}  />
            </MuiPickersUtilsProvider>
        </div>
    )
}
