import React, {useState,useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { AuthContext } from "../auth/Auth"
import MaterialTableDemo from "./MaterialTableDemo"

const useStyles = makeStyles({
  table: {
    maxWidth: 300,
  },
});

export default function SimpleTable(props) {
    const classes = useStyles();
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)
    var db = firebase.firestore()

    async function getHistory () {
        var history = []
        // eslint-disable-next-line
        var userHistory = await db.collection("users").doc(props.userEmail).collection("weights").orderBy("Date", "desc").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                var tempDate = doc.data().Date.toDate()
                history.push({date: tempDate.toLocaleString(), weight: doc.data().Weight, id: doc.id})
            });
        })
        console.log("history got history")
        return(history)
    }

    useEffect(() => {
        async function prepHistory(){
            setHistory(await getHistory())
            setLoading(false)
        }
        prepHistory()
    }, [])

    return (
        <div>
            {loading ?( <div>Loading...</div>):(<MaterialTableDemo history={history} userEmail={props.userEmail}/>)}
        </div>
    );
}