import React from 'react';
import MaterialTable from 'material-table';
import firebase from "firebase";
import "firebase/firestore"
import { Paper, Typography } from '@material-ui/core'

export default function MaterialTableDemo(props) {
  const [state, setState] = React.useState({
    columns: [
      { title: 'Weight', field: 'weight' },
      { title: 'Date', field: 'date' },
    ],
    data: props.history
  });
  const db = firebase.firestore()

  return (
    <MaterialTable
      title={<Typography variant="h4">History</Typography>}
      columns={state.columns}
      data={state.data}
      options={{
        search: false
      }}

      // components={{
      //   Container: props => <Paper {...props} elevation={0}/>
      // }}

      editable={{
        // onRowAdd: newData =>
        //   new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //       setState(prevState => {
        //         const data = [...prevState.data];
        //         data.push(newData);
        //         return { ...prevState, data };
        //       });
        //     }, 600);
        //   }),
        // onRowUpdate: (newData, oldData) =>
        //   new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //       if (oldData) {
        //         setState(prevState => {
        //           const data = [...prevState.data];
        //           data[data.indexOf(oldData)] = newData;
        //           return { ...prevState, data };
        //         });
        //       }
        //     }, 600);
        //   }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            
            setTimeout(() => {
              resolve();
              setState(prevState => {
                db.collection("users").doc(props.userEmail).collection("weights").doc(oldData.id).delete()
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
  );
}