import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function SelfReflectionWidget() {
  const classes = useStyles();
  const [ratings, setRatings] = useState([]);
  const db = firebase.firestore();
  const user = firebase.auth().currentUser;
  const userEmail = user.email;

  useEffect(() => {
    db.collection('users').doc(userEmail)
      .collection('self-reflection')
      .orderBy('date', 'desc')
      .onSnapshot((snapshot) => {
        const newRatings = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRatings(newRatings);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {ratings.map((rating) => (
          <Grid item xs={4} key={rating.id}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1">{rating.date.toDate().toDateString()}</Typography>
              <Typography variant="subtitle2">Rating: {rating.rating}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}