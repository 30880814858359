// CheckoutForm.js
import React, {useState,useEffect}  from 'react';
import {useHistory} from 'react-router-dom'
import {injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';


//import AddressSection from './AddressSection';
import CardSection from './CardSection';
import Coupon from './Coupon'
import Loading from "../layout/Loading"

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"

import { Button, Typography, TextField, Grid } from '@material-ui/core';



function CheckoutForm(props) {
  const [loading, setLoading] = useState(false)
  const browserHistory = useHistory()
  const [coupon, setCoupon] = useState(null)
  var token = false

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

  //this is a quick fix so users don't subscribe twice will be fixing asap
  async function fakeLoading(){
      await sleep(10000)
      browserHistory.push("/dashboard")
  }

  if(loading===true){
    fakeLoading()
  }


  const GooglePay = () => {
    const [paymentRequest, setPaymentRequest] = useState(null);
  
    useEffect(() => {
      const paymentRequest = props.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
  
      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentRequest);
          console.log("payment: ", result)
        }
         console.log("paymentErr: ", result)
      });
    }, []);

    
  
    if (paymentRequest) {
      return <PaymentRequestButtonElement options={{paymentRequest}} />
    }
  
    // Render a normal traditional checkout form instead.
    return null;
  }


  

  async function handleSubmit(ev) {
    ev.preventDefault();
    
    var db = firebase.firestore()
    var user = firebase.auth().currentUser

    

    //This makes sure users cant click submit more than once while the token is loading
    if(!token){

      
      // We don't want to let default form submission happen here, which would refresh the page.


      // Use Elements to get a reference to the Card Element mounted somewhere
      // in your <Elements> tree. Elements will know how to find your Card Element
      // becase only one is allowed.
      // See our getElement documentation for more:
      // https://stripe.com/docs/stripe-js/reference#elements-get-element

      //const cardElement = props.elements.getElement('card');

      // // From here we cal call createPaymentMethod to create a PaymentMethod
      // // See our createPaymentMethod documentation for more:
      // // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      // this.props.stripe
      //   .createPaymentMethod({
      //     type: 'card',
      //     card: cardElement,
      //     billing_details: {name: 'Jenny Rosen'},
      //   })
      //   .then(({paymentMethod}) => {
      //     console.log('Received Stripe PaymentMethod:', paymentMethod);
      //   });

      // // You can also use confirmCardPayment with the PaymentIntents API automatic confirmation flow.
      // // See our confirmCardPayment documentation for more:
      // // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
      // this.props.stripe.confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
      //   payment_method: {
      //     card: cardElement,
      //   },
      // });

      // // You can also use confirmCardSetup with the SetupIntents API.
      // // See our confirmCardSetup documentation for more:
      // // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
      // this.props.stripe.confirmCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', {
      //   payment_method: {
      //     card: cardElement,
      //   },
      // });

      // You can also use createToken to create tokens.
      // See our tokens documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-token
      // With createToken, you will not need to pass in the reference to
      // the Element. It will be inferred automatically.

      

      console.log("button pressed")
      token=true
      props.stripe.createToken().then(function(result){
          //push token to firebase here
          console.log("token: " + result.token)
          console.log("error: " + result.error)
          if(result.error == null){
            db.collection("stripe_customers").doc(user.email).collection("tokens").add({token: result.token}).then(
              setLoadingTrue()
              


              
              
            )
          }else{
            console.log(result.error)
            token=false
          }

          //!!!!!dont forget to handle errors!!!!!!!!!
          //If there were no errors send them to the enter weight page by pushing to browser history
      })


      /*
      // this.props.stripe.createToken().then(await function(result){
      //     //push token to firebase here

      //     db.collection("stripe_customers").doc(user.email).collection("charges").add({token: result.token})

      //     //!!!!!dont forget to handle errors!!!!!!!!!
      // })
      // token type can optionally be inferred if there is only one Element
      // with which to create tokens
      // this.props.stripe.createToken({name: 'Jenny Rosen'});

      // You can also use createSource to create Sources.
      // See our Sources documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-source
      // With createSource, you will not need to pass in the reference to
      // the Element. It will be inferred automatically.


      // this.props.stripe.createSource({
      //   type: 'card',
      //   owner: {
      //     name: 'Jenny Rosen',
      //   },
      // });
      */
    }
  };

  function setLoadingTrue(){
    setLoading(true)
  }


    return (
      <>{loading?(
        <>
          <Typography align="center" variant="h4">Processing Payment</Typography>
          <Loading/>
          </>
      ):(
        <>
        {/* <GooglePay/> */}
        <form onSubmit={handleSubmit}>
          <Typography style={{marginBottom: 10}} variant="h6" >2 Week Free Trial Then $5.00/month</Typography>
          <CardSection/>
          <Coupon setCoupon={setCoupon}/>
          <Button  style={{marginTop: 20}}color="primary" variant="contained" type="submit" fullWidth={true}>Confirm Payment and Start Trial</Button>
        </form>
        </>
      )}
        
      </>
    )
}

export default injectStripe(CheckoutForm);