import React, {useState} from "react"
import {Link} from "react-router-dom"
import { Button, Menu, MenuItem } from "@material-ui/core"
import FabSetup from "./FabSetup";
import { Fab, Typography } from '@material-ui/core'

import firebase from "firebase";
import "firebase/firestore"


export default function DropdownMenu(props){
    const [anchorEl, setAnchorEl] = useState(null);
    const [initials, setInitials] = useState("")
    const db = firebase.firestore()

    async function getInitials(){
        const data = (await db.collection("users").doc(props.currentUser.email).get()).data()
        var x = "hi"
        if(data.firstname.length > 0){
            setInitials(data.firstname[0] + data.lastname[0])
        }
        
    }
    getInitials()

    const handleClick = event => {
        console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

    return(
        <>
            {/* <FabSetup currentUser={props.currentUser}/> */}
            <div style={{paddingLeft: 10}}>
            {initials!==""?
                <Fab disableRipple aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} size="small"><Typography variant="h6">{initials}</Typography></Fab>
                :
                <Button variant="outlined" color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Menu 
                </Button>
            }
            </div>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                <MenuItem component={Link} to={"/dashboard"} onClick={handleClose}>Dashboard</MenuItem>
                <MenuItem component={Link} to={"/account"} onClick={handleClose}>Settings</MenuItem>
                <MenuItem component={Link} to={"/affiliate"} onClick={handleClose}>Refer a friend</MenuItem>
                <MenuItem component={Link} to={"/contact"} onClick={handleClose}>Contact Us</MenuItem>
                <MenuItem onClick={props.signOut}>Sign Out</MenuItem>
            </Menu>
        </>
    )
}