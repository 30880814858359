import React, { useState, useEffect } from 'react';
import { Card, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, CardContent, Typography } from '@material-ui/core';
import firebase from 'firebase';
import { useCalories } from '../../util/CalorieContext';

const CalorieTracker = () => {
  const [foodDesc, setFoodDesc] = useState('');
  const [calories, setCalories] = useState('');
  const { totalCalories, setTotalCalories } = useCalories();
  const [entries, setEntries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const user = firebase.auth().currentUser;
      const today = new Date().toDateString();
      const dbRef = firebase.firestore().collection('users').doc(user.email).collection('calorie-tracker').doc(today);
      const doc = await dbRef.get();
      if (doc.exists && Array.isArray(doc.data().entries)) {
        setEntries(doc.data().entries);
      }
    }
    fetchData();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    const newEntry = {
      foodDesc,
      calories,
    };
    let updatedEntries;
    if (editingIndex !== null) {
      updatedEntries = [...entries];
      updatedEntries[editingIndex] = newEntry;
      setEditingIndex(null);
    } else {
      updatedEntries = [...entries, newEntry];
    }
    setEntries(updatedEntries);
    await saveToFirebase(updatedEntries);
    setFoodDesc('');
    setCalories(0);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setFoodDesc(entries[index].foodDesc);
    setCalories(entries[index].calories);
  };

  const handleDelete = async (index) => {
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setEntries(updatedEntries);
    await saveToFirebase(updatedEntries);
  };

  const saveToFirebase = async (updatedEntries) => {
    const user = firebase.auth().currentUser;
    const today = new Date().toDateString();
    const dbRef = firebase.firestore().collection('users').doc(user.email).collection('calorie-tracker').doc(today);
    await dbRef.set({ entries: updatedEntries });
  };

  const calculateTotalCalories = () => {

    const sum = entries.reduce((total, entry) => total + Number(entry.calories), 0)
    
    setTotalCalories(sum);

    return(sum)
  };

  const displayEntries = (
    <Table style={{ marginBottom: '20px' }}>
      <TableHead>
        <TableRow>
          <TableCell>Food & Amount</TableCell>
          <TableCell align="right">Calories</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(entries) && entries.map((entry, index) => (
          <TableRow key={index}>
            <TableCell>{entry.foodDesc}</TableCell>
            <TableCell align="right">{entry.calories}</TableCell>
            <TableCell align="right">
              <Button onClick={() => handleEdit(index)}>Edit</Button>
              <Button onClick={() => handleDelete(index)}>Delete</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Card>
      <CardContent>
        {displayEntries}
      </CardContent>
      <form onSubmit={handleSave} style={{ marginBottom: '20px' }}>
        <TextField 
          label="Food & Amount" 
          value={foodDesc}
          onChange={e => setFoodDesc(e.target.value)}
        />
        <TextField 
          label="Calories" 
          value={calories}
          type="number"
          onChange={e => setCalories(e.target.value)}
        />
        <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px' }}>
          <Button type="submit" style={{ backgroundColor: '#4CAF50', color: 'white' }}>
            {editingIndex !== null ? 'Update' : 'Save'}
          </Button>
        </div>
      </form>
      <Typography style={{ fontWeight: 'bold', marginTop: '20px' }}>
          Total Calories: {calculateTotalCalories()}
        </Typography>
    </Card>
  );
}

export default CalorieTracker;
