import React, {useContext} from "react";
import {Link,NavLink} from "react-router-dom";
import {AppBar, Toolbar, Typography, Button, Fab} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase"
import "firebase/auth"
import {useHistory} from "react-router-dom"

import { AuthContext } from "./auth/Auth"
import Unsubscribe from "./payment/unsubscribe/Unsubscribe"
import DropdownMenu from "./layout/DropdownMenu";
import FabSetup from "./layout/FabSetup";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textDecoration: 'none',
    color: 'white'
  },
  link: {
    textDecoration: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

function Navbar() {
  let history = useHistory()
  const classes = useStyles()
  const {currentUser} = useContext(AuthContext)

  function signOut(){
    firebase.auth().signOut()
    history.push("/signin")
  }

  return(
    <div  className={classes.root}>
      <AppBar   position="static" >
        <Toolbar className={classes.toolbar}>
          <Typography component={NavLink} to={"/"} className={classes.title} variant="h5">
            Fasting Pal
          </Typography>
          <div>
            {!currentUser?<Button style={{marginRight: "5px"}} component={Link} to={"/signin"} color="inherit" variant="outlined"> 
              Sign In
            </Button>:null }
            {!currentUser?<Button component={Link} to={"/signup"} color="inherit" variant="outlined"> 
              Sign Up
            </Button>:null}
          </div>
          {currentUser?(
            <>
              {/* <Button onClick={signOut} component={Link} to={"/"} color="inherit" variant="outlined"> 
                Sign Out
              </Button>
              <Button component={Link} to={"/account"} color="inherit" variant="outlined">
                Account
              </Button> */}
              <DropdownMenu signOut={signOut} currentUser={currentUser}/>
            </>
          ):null}
          {/* {currentUser?<Button component={Link} to={"/subscribe"} color="inherit" variant="outlined"> 
            Subscribe
          </Button>:null} */}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar;