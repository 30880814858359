import React, {useEffect, useState} from "react"
import firebase from "firebase/app";
import "firebase/auth";

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        const abortController = new AbortController()
        firebase.auth().onAuthStateChanged(setCurrentUser) 
        
        return () => {
            abortController.abort();
        }
    }, [])

    return (
        <AuthContext.Provider 
        value={{
            currentUser
        }}
        >
            {children}
        </AuthContext.Provider>
    )
}