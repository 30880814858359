import { createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {},
  palette: {},
  breakpoints: {
    xs: "0px",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px"
  }
});