import React, { useState } from 'react'
import { Button, Dialog,DialogTitle, DialogActions,DialogContent,DialogContentText } from '@material-ui/core'
import ReactPhoneInput from "react-phone-input-2"

import firebase from "firebase"
import "firebase/firestore"

import ReactCodeInput from 'react-verification-code-input';
import MuiPhoneNumber from "material-ui-phone-number"
import axios from 'axios'
import Loading from '../../../layout/Loading'
 
export default function EnableNotifications(props) {
    const [loading,setLoading] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [verify, setVerify] = useState(false);
    const [verifyLoading,setVerfiyLoading] = useState(false);
    const [phone,setPhone] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    const state = { phone: "" };

    const handleOnChange = value => {
        console.log(value);
        setPhone(value)
    };

    async function handleClick(){
        const parsedPhone = phone.replace(/[(]|[)]|-|[ ]/g, "")
        axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/sendVerification/' + props.email + '/' + parsedPhone)
        setVerify(true)
    }

    async function verifyNumber(event){
        setVerfiyLoading(true)
        const db = firebase.firestore()
        var result = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/verifyPhoneNumber/' + props.email + '/' + event)

        if(result.data === true){
            const now = new Date()
            const date = new Date(now.setHours(10,0,0,0))
            const parsedPhone = phone.replace(/[(]|[)]|-|[ ]/g, "")
            //the number is verified save it to the database
            db.collection("users").doc(props.email).set(
                {
                    phoneNumber: parsedPhone, 
                    notify: true,
                    notificationActive: [true,false,false],
                    notificationTimes: [date]
                }
                ,{merge:true})
                
            props.setNotificationActive(null)
            props.setNotify(true)
            handleClose()

        }else{
            //the number is not verified do not save it and show error to user

            //clear the fields and have them try again
            setVerfiyLoading(false)
            //TODO: limit the number of attemps to prevent brute force
        }
    }

    return (
        <div>
            {loading?  (<div><Loading/></div>):(
            <Button color="primary" variant="outlined" onClick={handleClickOpen} style={{marginTop: 20}}>Enable Text Notifications</Button>
            )}
            <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {(!verify)?
                    <>
                    <DialogTitle id="alert-dialog-title">{"Enter Your Phone Number:"}</DialogTitle>
                    <DialogContent>
                      {/* <DialogContentText id="alert-dialog-description">
                        This will delete all of the weights you have entered in the past
                      </DialogContentText> */}
                      {/* <ReactPhoneInput
                        inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true
                        }}
                        defaultCountry={"us"}
                        value={phone}
                        onChange={handleOnChange}
                        /> */}
                        <MuiPhoneNumber defaultCountry={'us'} onChange={handleOnChange}/>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} >
                        Cancel
                      </Button>
                      <Button onClick={handleClick} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                    </>
            :  (<>
                <DialogTitle id="alert-dialog-title">{"Enter Verification Code"}</DialogTitle>
                <DialogContent>
                    {verifyLoading?<div><Loading/></div>
                    :
                    (
                        <div>
                            <ReactCodeInput onComplete={verifyNumber}/>
                            <DialogContentText id="alert-dialog-description">
                                You should receve your code within a few minutes
                            </DialogContentText>
                        </div>
                    )}
                 </DialogContent>
                    </>)
             } 
          </Dialog>
        </div>
    )
}