import React from "react"
import {StripeProvider} from 'react-stripe-elements';
import MyStoreCheckout from './MyStoreCheckout'
import NoCardNeeded from "./NoCardNeeded"
import { Container, Paper, Typography } from "@material-ui/core";
/* 
    TODO: If a user is subscribed don't let them subscribe again. 
    If the users subscription is set to end on a date and they resubscribe
    add the new payment method and change the cancel at period end to false

    Also allow users to add coupons
*/
export default function Subscribe() {
    return(
        <Container maxWidth="sm">
            <Typography variant="h5" align="center">Subscribe to Fasting Pal</Typography>
            <Paper style={{padding: 10}} elevation={5}>
                <StripeProvider apiKey="pk_live_qeS6Xwl6tkIdgNSbFdyb6VXO009eNOxIhk" >
                {/* <StripeProvider apiKey="pk_test_m3UpqK8086Lam3NJpwrsXHjg00OoDEnHOa" > */}
                    <MyStoreCheckout />
                </StripeProvider>
            </Paper>

            <NoCardNeeded/>
        </Container>
    )
}