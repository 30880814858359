import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom"
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import Cookies from "universal-cookie"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <MLink color="inherit" href="https://fastingpal.com/">
        FastingPal.com
      </MLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignUp() {
    const classes = useStyles();
    let history = useHistory();
    var db = firebase.firestore()
    const cookies = new Cookies();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [accept, setAccept] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    console.log(accept)

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function fakeLoading(){
      var x = await sleep(10000)
      return (x)
    }
    async function signedUp() { 
        var errorCode = "";
        var errorMessage = "";
        var isError = false;
        //can also create stripe user with public key to save on firebase usage
        if(accept){
          await firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
              // Handle Errors here.
              errorCode = error.code;
              errorMessage = error.message;
              isError = true;
              // ...
              // console.log("error code: " + errorCode)
              // console.log("error message: " + errorMessage)
              setErrorMsg(errorMessage)
            })

            if (!isError) {
                const ref = await cookies.get('ref')
                if(ref){
                  //user was reffered
                  console.log("ref:", ref)
                  const date = new Date()
                  db.collection("referrals").doc(date.getUTCFullYear() + " " + date.getUTCMonth()).collection(ref).doc(email).set({signedUp: true})
                  await db.collection("users").doc( email.toLowerCase() ).set({
                    firstname: firstName,
                    lastname: lastName,
                    uid: firebase.auth().currentUser.uid,
                    ref: ref
                  })
                }else{
                  //user was not reffered
                  await db.collection("users").doc( email.toLowerCase() ).set({
                    firstname: firstName,
                    lastname: lastName,
                    uid: firebase.auth().currentUser.uid
                  })
                }
                history.push("/Subscribe")
                //history.push("/Dashboard")
            }
        }else{
          setErrorMsg("You must agree to the terms before signing up")
        }
    }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up to Fasting Pal
        </Typography>
        <form onSubmit={signedUp} className={classes.form} noValidate = {false}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" onChange={e => setAccept(e.target.checked)}/>}
                label={<Typography>I agree to the terms of the <a href="https://www.fastingpal.com/privacypolicy" target="_blank" color=""><MLink>Privacy Policy</MLink></a> and the 14 day <a href="https://www.fastingpal.com/refundpolicy" target="_blank" color=""><MLink>Refund Policy</MLink></a></Typography>}
              ></FormControlLabel>
            </Grid>
          </Grid>
          <Typography color="error">{errorMsg}</Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick = {signedUp}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
                <Link path to="/signin">
                    <MLink href="#" variant="body2">
                        Already have an account? Sign in
                    </MLink>
                </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
