import React from "react"
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Container} from "@material-ui/core";

//https://github.com/kevinsqi/react-circular-progressbar
//This link is the progress bar I would like to use for the fasting timer. 

export default function ProgressBar(props) {
    const hours = props.timeLeft.hours//('0'+props.timeLeft.hours).slice(-2)
    const minutes = ('0'+props.timeLeft.minutes).slice(-2)
    const seconds = ('0'+props.timeLeft.seconds).slice(-2)
    return(
        <Container maxWidth="sm">
            <CircularProgressbar 
            value={props.timeLeft.percentage || 0}
            styles={buildStyles({
                pathColor: props.timeLeft.color || "none"
              })}
            text={(props.timeLeft.color)?(`${hours + ":" + minutes + ":" + seconds}`):(null)} />
        </Container>
    )
}