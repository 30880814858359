import React from 'react'
import { Button, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { useStore } from "../../../util/globalStore"
import InputSlider from './widgets/InputSlider';

export default function ResetCalculations() {
    const db = firebase.firestore()
    const [open, setOpen] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [hours, setHours] = React.useState(8)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setChanged(false);
    };
    //get user info from global store
    const user = useStore(state => state.user);

    //add a new goal with the recent required weight 4 pounds higher(or offset higher)
    async function performReset(){
        console.log(user.email)
        //db.collection("users").doc(user.email).collection("goals").

        //copy the function called change goal per week from goal per week and use it here
        const result = await db.collection("users").doc(user.email).get()
        const currentDate = new Date()
        const requiredWeight = result.data().recentRequiredWeight
        //Get the users current goal 
        //console.log(result.data().goalPerWeek)
        const goalPerWeek = (result.data().goalPerWeek !== undefined)?result.data().goalPerWeek:1
        //const mostRecentWeightAvg = 
        const desiredEatingWindow = hours
        const offset = (result.data().unit === "lbs")?8:5

        console.log(goalPerWeek)

        //adjusts the variables associated with the current user
        db.collection("users").doc(user.email).set({
            goalPerWeek: goalPerWeek, 
            recentGoalDate: new Date(), 
            recentGoalFastPeriod: desiredEatingWindow, //instead of this i could just use recent req weight
            recentGoalWeightAvg: result.data().mostRecentWeightAvg,    //instead of this i could just use recent req weight
            recentGoalRequiredWeight: result.data().mostRecentWeightAvg + ((offset)/8)*desiredEatingWindow 
        },{merge: true})

        //adds a document to the goals collection
        db.collection("users").doc(user.email).collection("goals").doc(currentDate.toDateString()).set({
            goalPerWeek: goalPerWeek, 
            goalDate: new Date(), 
            recentGoalFastPeriod: desiredEatingWindow,  //instead of this i could just use recent req weight
            requiredWeight: result.data().mostRecentWeightAvg + ((offset)/8)*desiredEatingWindow       //instead of this i could just use recent req weight
        })

        setChanged(true)

    }
    return (
        // <div>
        //     <Button style={{marginTop: 20, marginBottom:20}}  variant="outlined" color="primary" onClick={performReset}>reset calculations</Button>
        // </div>

        <div>
        <Button style={{marginTop: 20}}  variant="outlined" color="primary" onClick={handleClickOpen}>
        reset calculations
        </Button>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        {(!changed)?
            (
                <>
                <DialogTitle id="alert-dialog-title">{"Select Desired Eating Window"}</DialogTitle>
                <DialogContent>
                    <InputSlider setHours={setHours}/>
                    <br/>
                    <DialogContentText id="alert-dialog-description">
                        This will reset your eating window to {hours} hours 
                    </DialogContentText>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={performReset} color="primary" autoFocus>
                    reset calculations
                    </Button>
                </DialogActions>
                </>
                )
        :  (<>
                <DialogTitle id="alert-dialog-title">{"Successfully Reset"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your eating window has been adjusted, go to the dashboard to see the change.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                        <Button onClick={handleClose} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </>)
        }
        </Dialog>
        </div>
    )
}
