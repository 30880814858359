import React from 'react';
import ReactApexChart from 'react-apexcharts'
import firebase from "firebase";
import "firebase/firestore";

import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  chart: {
    marginTop: theme.spacing(3),
  },
});


class ApexChart extends React.Component {
  
    constructor(props) {
      super(props);

      var bottomLine = []
      var topLine = []
      const numOfDays = 3
      var db = firebase.firestore()
      const now = new Date()
      const offsetChangeDay = 10
      const offsetChangeMonth = 5
      const offsetChangeYear = 2020


      const offsetChange = offsetChangeYear*365 + offsetChangeMonth*30 + offsetChangeDay
    //Get Top Goal Line From Bottom
    async function bottomGoal(){
      //const user = await db.collection("users").doc(props.userEmail).get()
      //const offset = user.data().unit == 'lbs'? 4:2.5
      for(var i = 0; i < props.requiredWeights.length;i++){
        //console.log(props.requiredWeights[i][0].getMonth(), props.requiredWeights[i][0].getDate(), props.requiredWeights[i][0].getFullYear(), sum, offsetChange)
        const month = props.requiredWeights[i][0].getMonth()
        const day = props.requiredWeights[i][0].getDate()
        const year = props.requiredWeights[i][0].getFullYear()
        const sum = year*365 + month*30 + day
        if(sum < offsetChange){
          topLine.push([props.requiredWeights[i][0],Math.round((props.requiredWeights[i][1])*10)/10])
          bottomLine.push([props.requiredWeights[i][0],Math.round((props.requiredWeights[i][1] - props.offset)*10)/10])
        }else{
          topLine.push([props.requiredWeights[i][0],Math.round((props.requiredWeights[i][1])*10)/10])
          bottomLine.push([props.requiredWeights[i][0],Math.round((props.requiredWeights[i][1] - props.offset)*10)/10])
        }
        //bottomLine.push([props.requiredWeights[i][0],Math.round((props.requiredWeights[i][1] - props.offset)*10)/10])
      }
    }
    //this if statement prevents the top goal line from going crazy due the the array being written to twice.
    //for some reason when I switch routes the components aren't resetting the arrays.
    if(bottomLine.length===0){
      bottomGoal()
    }

    //Get bottom goal line by subtracting the top line by maxdiff
      this.state = {
        series: [{
          //type: 'area',
          name: "Weight",
          data: props.history,
        },
        {
          name: "bottom goal",
          data: bottomLine,
        },
        {
          name: "top goal",
          data: topLine
        },
        // {
        //   name: "Moving Average",
        //   data: movingAverage
        // },
        
      ],

        options: {
          chart: {
            zoom:{
              enabled: false,
            },
            toolbar: {
              show: false
            },
            type: 'area',
            height: 350
          },
          stroke: {
            show: true,
            curve: ['straight','straight','straight','straight'],
            dashArray: [0, 8, 5]
          },
          annotations: {
            yaxis: [{
              y: 30,
              borderColor: '#999',
              label: {
                show: true,
                text: 'Support',
                style: {
                  color: "#fff",
                  background: '#00E396',
                }
              }
            }],
            xaxis: [{
              x: new Date('14 Nov 2012').getTime(),
              borderColor: '#999',
              yAxisIndex: 0,
              label: {
                show: true,
                text: 'Rally',
                style: {
                  color: "#fff",
                  background: '#775DD0'
                }
              }
            }]
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: [3,0,0,0],
            style: 'hollow',
          },
          xaxis: {
            type: 'datetime',
            //min: new Date('01 Mar 2012').getTime(),
            //tickAmount: 6,
          },
          yaxis:{
            show: true,
            labels: {
              show: true,
              maxWidth: 35,
            }
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy'
            }
          },
          fill: {
            type: ['gradient','image','image',"image"],
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
        },
        selection: 'all',
      };
    }

    updateData (timeline) {
      this.setState({
        selection: timeline
      })

      switch (timeline) {
        case 'one_month':
          this.setState({
            options: {
              xaxis: {
                min: new Date('28 Jan 2013').getTime(),
                max: new Date('27 Feb 2013').getTime(),
              }
            }
          })
          break;
        case 'six_months':
          this.setState({
            options: {
              xaxis: {
                min: new Date('27 Sep 2012').getTime(),
                max: new Date('27 Feb 2013').getTime(),
              }
            }
          })
          break;
        case 'one_year':
          this.setState({
            options: {
              xaxis: {
                min: new Date('27 Feb 2012').getTime(),
                max: new Date('27 Feb 2013').getTime(),
              }
            }
          })
          break;
        case 'ytd':
          this.setState({
            options: {
              xaxis: {
                min: new Date('01 Jan 2013').getTime(),
                max: new Date('27 Feb 2013').getTime(),
              }
            }
          })
          break;
        case 'all':
          this.setState({
            options: {
              xaxis: {
                min: undefined,
                max: undefined,
              }
            }
          })
          break;
        default:
      }
    }

    render() {
      const { classes } = this.props;
      return (
        <div id="chart">
          {/* <div className="toolbar">
          
            <button id="one_month"
                onClick={()=>this.updateData('one_month')} className={ (this.state.selection==='one_month' ? 'active' : '')}>
            1M
            </button>
            &nbsp;
            <button id="six_months"
                onClick={()=>this.updateData('six_months')} className={ (this.state.selection==='six_months' ? 'active' : '')}>
            6M
            </button>
            &nbsp;
            <button id="one_year"
                onClick={()=>this.updateData('one_year')} className={ (this.state.selection==='one_year' ? 'active' : '')}>
            1Y
            </button>
            &nbsp;
            <button id="ytd"
                onClick={()=>this.updateData('ytd')} className={ (this.state.selection==='ytd' ? 'active' : '')}>
            YTD
            </button>
            &nbsp;
            <button id="all"
                onClick={()=>this.updateData('all')} className={ (this.state.selection==='all' ? 'active' : '')}>
            ALL
            </button>
        </div> */}

          <div>
            <Typography variant="h6" gutterBottom></Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  <div id="chart-timeline">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="area"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
}

export default ApexChart