import create from 'zustand'

export const [useStore] = create(set => ({
  history: null,

  setHistory: (newHistory) => set({
    history: newHistory
  }),

  user: null, 

  setUser: (newUser) => set({
    user: newUser
  }),
}));