import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { useStore } from "../../util/globalStore";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export default function UserRatingsCalendar() {
  const [events, setEvents] = useState([]);
  const user = useStore(state => state.user);
  const db = firebase.firestore();

  useEffect(() => {
    const fetchData = async () => {
      // Get user's self-reflection data from Firestore
      const selfReflectionSnapshot = await db
        .collection("users")
        .doc(user.email)
        .collection("self-reflection")
        .get();

      // Convert Firestore data to an array of events
      const selfReflectionEvents = selfReflectionSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          start: data.date.toDate(),
          end: data.date.toDate(),
          title: data.rating,
        };
      });

      setEvents(selfReflectionEvents);
    };

    fetchData();
  }, [user, db]);

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "500px" }}
      />
    </div>
  );
}