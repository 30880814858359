import React from 'react'
import {useLocation} from "react-router-dom"
import axios from 'axios'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ConnectOAuth() {
    let query = useQuery();
    const state=query.get("state")
    const authCode=query.get("code")

    async function connect(){
        console.log("starting connect...")
        var x = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/connect/affiliate/'+state+'/'+authCode)
        console.log(x)
    }
    
    connect()

    return (
        <div>
            <h1>Loading...</h1>
        </div>
    )
}
