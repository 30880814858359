import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import VolumeUp from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
});

export default function InputSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(8);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    props.setHours(newValue)
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
    props.setHours(event.target.value === '' ? '' : Number(event.target.value))
  };

  const handleBlur = () => {
    if (value < 1) {
      setValue(1);
      props.setHours(1)
    } else if (value > 12) {
      setValue(12);
      props.setHours(12)
    }
  };

  return (
    <div className={classes.root}>
      {/* <Typography variant="h6" id="input-slider" gutterBottom>
        Select Desired Eating Window
      </Typography> */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {/* <VolumeUp /> */}
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={1}
            min = {1}
            max = {12}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 1,
              max: 12,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}