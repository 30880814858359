import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as firebase from "firebase/app"
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics'
import "./css/main.css"
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import {theme} from "./util/theme"

var firebaseConfig = {
    apiKey: "AIzaSyD0N6jT9jZ-7cKURGbC7wca-Z2dEAhvLrI",
    authDomain: "fastingpal.firebaseapp.com",
    databaseURL: "https://fastingpal.firebaseio.com",
    projectId: "fastingpal",
    storageBucket: "fastingpal.appspot.com",
    messagingSenderId: "290086846130",
    appId: "1:290086846130:web:528459de404a1a476353ab",
    measurementId: "G-RS5SRBX3K5"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

//const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

function Index() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    );
  }

ReactDOM.render(
    <Index />, 
  document.getElementById("root"));