import React, {useState, useContext, useEffect} from "react";
import {useHistory , Link} from "react-router-dom"
import FastingTimer from "./layout/FastingTimer"
import Graph from "./data/Graph"
import axios from 'axios'
import SubscriptionStatus from "./auth/SubscriptionStatus"
import firebase from "firebase";
import "firebase/auth"
import "firebase/firestore"
import { AuthContext } from "./auth/Auth"
import { Typography, Button, Paper, Container, Card, Grid } from "@material-ui/core";
import History from "./data/History"
import { useStore } from "../util/globalStore";
import SelfReflectionWidget from "./data/SelfReflectionWidget";
import UserRatingsCalendar from "./data/UserRatingsCalendar";
import SelfReflectionCalendar from "./data/SelfReflectionCalendar";
import CalorieTracker from "./data/CalorieTracker";
import { CalorieProvider } from "../util/CalorieContext";

var globalRequiredWeights = null
var tempRequiredWeight = null
var debugging = true

export default function Dashboard() {
    console.log("dashboard loaded")
    const user = useStore(state => state.user);
    const [x,setX] = useState(null)
    const [isSubscribed, setIsSubscribed] = useState(false)
    // eslint-disable-next-line
    const [subChecked, setSubChecked] = useState(false)
    const [fastStartTime,setFastStartTime] = useState(null)
    const todaysDate = new Date()
    const [offset,setOffset] = useState(null)
    const [selfReflectionEnabled, setSelfReflectionEnabled] = useState(false);

    let browserHistory = useHistory()
    //const {currentUser} = useContext(AuthContext)
    var db = firebase.firestore()
    function dateDiffInDays(a, b) {
        if(a && b){
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        
            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        }else{
            return(a)
        }
    }

    function getAverageWeight(history,numOfDays){
        var average = 0
        var total = 0
        if(history.weight.length<numOfDays){
            numOfDays = history.weight.length
        }
        for (var i=0;i<numOfDays;i++){
          total += history.weight[i]
        }
        average = total/numOfDays       
        return(average)
    }

    async function calculateFastingPeriod (userEmail) {
        if(debugging===true)console.log("calculating fasting period")
        const useAverage = true

        //this gets all the variables stored in the users database
        const result = await db.collection("users").doc(userEmail).get()

        if(result.data().unit==="lbs"){
            //setting for pounds
            var maxDiff = 8
            setOffset(8)
        }else{
            //setting for kg
            var maxDiff = 5
            setOffset(5)
        }

        const todaysDate =new Date()

        //Get the starting date and weight from the history array
        var history= await getHistory(userEmail)

        //If the user didn't enter a weight today send them to the enter weight page.
        //if they haven't selected a unit yet send them to that page
        if(result.data().unit===undefined){
            browserHistory.push("/initialSettings")
        }else if ((history.date.length!=0 && (todaysDate.getFullYear()===history.date[0].getFullYear()))&&(todaysDate.getMonth()===history.date[0].getMonth())&&(todaysDate.getDate() === history.date[0].getDate())){
            //The user has a weight entered for today
            //console.log("weight is here")
        }else if(isSubscribed===true){
            //the user does not have a weight for today.
             browserHistory.push("/EnterWeight")
        }else{
            browserHistory.push("/EnterWeight")
        }

        var startWeight = history.weight[history.weight.length - 1]
        var startRequiredWeight = startWeight + maxDiff
        var startDate = history.date[history.date.length - 1]
        var recentDate = history.date[0]

        if(startWeight!== undefined){
            db.collection("users").doc(userEmail).set({startWeight: startWeight, startDate: startDate, recentDate: recentDate},{merge: true})
        }

        //Get the Weight the user entered today and the current date
        var currentWeight = history.weight[0]
        var currentDate = history.date[0]
        var currentId = history.id[0]
        const now = new Date()

        //Create a function that gets the users average weight over a specified amound of days
        function getBestAverage(){
            const minDays = 1
            //TODO: max days should depend on the most recent reset date.
            if(recentGoalDate){
                var diffInDays = dateDiffInDays(recentGoalDate,currentDate)
            }else{
                var diffInDays = dateDiffInDays(startDate, currentDate)
            } 
            var maxDays = 3

            if(diffInDays < maxDays){
                maxDays = diffInDays
                if(maxDays == 0){
                    maxDays = 1
                }
            }
            var lowestAverageWeight = null
            var average = null
            for(var i=minDays;i<=maxDays;i++){
                average = getAverageWeight(history,i)

                if(lowestAverageWeight===null || (average<lowestAverageWeight)){
                    lowestAverageWeight=average
                }
            }
            return(lowestAverageWeight)
        }
        if(useAverage === true){
            currentWeight = getBestAverage()
        }

        let goalPerWeek = .5
        let recentGoalDate = null
        let goalFastPeriod = null

        //if startGoalPerWeek exists than that means the user changed the default goal
        if(result.data().startGoalPerWeek){
            goalPerWeek = result.data().startGoalPerWeek
        }

        //if goalPerWeek exists then that mean the user changed the default goal
        if(result.data().goalPerWeek){
            goalPerWeek = result.data().goalPerWeek
            recentGoalDate = result.data().recentGoalDate.toDate()
            //goalFastPeriod = result.data().recentGoalFastPeriod
            if(result.data().recentGoalRequiredWeight!== undefined){
                startRequiredWeight = result.data().recentGoalRequiredWeight
            } else {
                startRequiredWeight = await getRecentGoal(userEmail)
            }
        }

        var goalPerDay = goalPerWeek/7

        //console.log(startDate,   currentDate)
        //get the difference in days from startdate to today
        if(recentGoalDate){
            var diffInDays = dateDiffInDays(recentGoalDate,currentDate)
        }else{
            var diffInDays = dateDiffInDays(startDate, currentDate)
        }

        //Calculate the required weight (SW - diffInDays*GPD)
        var requiredWeight = (startRequiredWeight - (diffInDays*goalPerDay))
        tempRequiredWeight = requiredWeight

        //Calculate the fasting period for today
        var startingFastPeriod = 8
        var diffIntervals = maxDiff/startingFastPeriod
        var diffInWeights = currentWeight - requiredWeight + maxDiff

        if(goalFastPeriod){
            var fastPeriod = goalFastPeriod - diffInWeights/diffIntervals
        }else{
            var fastPeriod = startingFastPeriod - diffInWeights/diffIntervals
        }

        //This makes the minimum fasting period 1 hour no matter what weight the user enters.
        var roundedFastPeriod
        if(fastPeriod>1){
            roundedFastPeriod = Math.floor(fastPeriod)
            setX(fastPeriod)
        }else{
            roundedFastPeriod = 1
            setX(1)
        }

        if(result.data().fastStartTime!==undefined){
            var fastEndTime = new Date(result.data().fastStartTime.toDate())
            fastEndTime = new Date(fastEndTime.setHours(fastEndTime.getHours()+roundedFastPeriod, fastEndTime.getMinutes(),0,0))
        }
        
        //store fasting period in database with the most recent weight entered.
        console.log("recentDate",recentDate.getDate(), currentDate.getDate())
        const recentDoc = await db.collection("users").doc(userEmail).collection("weights").doc(currentId).get()
        console.log("recentDoc", recentDoc.data().Date.toDate().getDate())
        if(currentId && (recentDoc.data().Date.toDate().getDate() === now.getDate())){

            db.collection("users").doc(userEmail).collection("weights").doc(currentId).set(
                {
                    fastPeriod: fastPeriod, 
                    requiredWeight: requiredWeight,
                    offset: offset
                },{merge: true})
            db.collection("users").doc(userEmail).set(
                {
                    recentRequiredWeight: requiredWeight ,
                    mostRecentFastPeriod: fastPeriod, 
                    mostRecentFastPeriodRounded: roundedFastPeriod, 
                    mostRecentWeightAvg: currentWeight,
                    totalWeights: history.weight.length,
                    //fastEndTime: fastEndTime
                },{merge: true})
        }else{
            //If The user doesn't have any weights entered in the database this sends them to the enter weight page
            if(result.data().unit){
                browserHistory.push("/enterweight")
            }
                
        }
        //Gets the users fastStartTime from database if it exists 
        if (result.data().fastStartTime) {
            const startTime = result.data().fastStartTime.toDate()
            const startTimeHours = startTime.getHours()
            const startTimeMinutes = startTime.getMinutes()
            const fastEndDate = new Date(new Date().setHours(startTimeHours + roundedFastPeriod,startTimeMinutes,0,0))
            db.collection("users").doc(userEmail).set({
            //fastStartTime: newStartTime,
            fastEndTime: fastEndDate
            },{ merge: true })

            setFastStartTime(startTimeHours+(startTimeMinutes/60))
        } else {
            // doc.data() will be undefined in this case
            const tempStartTIme = new Date(new Date().setHours(12,0,0,0))
            db.collection("users").doc(userEmail).set({fastStartTime: tempStartTIme},{merge: true})
            setFastStartTime(12)
        }
        
    }

    async function getRecentGoal(userEmail){
        var userGoals = await db.collection("users").doc(userEmail).collection("goals").orderBy("goalDate", "desc").get()
        return(userGoals.docs[0].data().requiredWeight)
    }

    async function getHistory(userEmail) {
        var history = {
            id: [],
            date: [],
            weight: []
        }
        var requiredWeights ={
            weight: [],
            date: []
        }
        // eslint-disable-next-line
        var userHistory = await db.collection("users").doc(userEmail).collection("weights").orderBy("Date", "desc").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                history.id.push( doc.id) 
                history.date.push( doc.data().Date.toDate() )
                history.weight.push( doc.data().Weight )

                if(doc.data().requiredWeight!==undefined){
                    requiredWeights.weight.push(doc.data().requiredWeight)
                    requiredWeights.date.push(doc.data().Date.toDate())
                }
            });
            globalRequiredWeights=requiredWeights
        })
        
        if(debugging===true)console.log("dashboard got history")
        //await setHistory(history)
        //setData(Object.values(getHistory))
        return(history)
    }

    async function calculateFastingPeriodFirebase(userEmail){
        var tempDate = new Date()
        var tz = tempDate.getTimezoneOffset()
        var result = await axios.get('https://us-central1-fastingpal.cloudfunctions.net/get/calculateFastingPeriod/' + userEmail)

        //console.log("testing axios: ", result, tz)
        setX(result.data.fastPeriod)
        setFastStartTime(result.data.fastStartTime - (tz/60))
    }

    if (user) {
        if(debugging===true)console.log("user signed in: dashboard")
        var userEmail = user.email
        
        if(x===null){
            //calculateFastingPeriodFirebase(userEmail)
            calculateFastingPeriod(userEmail)
            getSelfReflectionStatus(userEmail)
        }
    } else {
        //user is null
        //window.location.reload(false);
      if(debugging===true)console.log("user is null: dashboard")
    }

    // function that gets the self reflection status from the database
    async function getSelfReflectionStatus(userEmail) {
        const userRef = firebase.firestore().collection("users").doc(userEmail);
        const userSnapshot = await userRef.get();
        setSelfReflectionEnabled(userSnapshot.data().selfReflectionEnabled);
        }


    // useEffect(() => {
    //     const fetchData = async () => {
    //       const userRef = firebase.firestore().collection("users").doc(userEmail);
    //       const userSnapshot = await userRef.get();
    //       setSelfReflectionEnabled(userSnapshot.data().selfReflectionEnabled);
    //     }
    //     fetchData();
    //   }, [userEmail]);

    return (
        <>
        <CalorieProvider>
        <div>
            <SubscriptionStatus setIsSubscribed={setIsSubscribed} setSubChecked={setSubChecked} user={user}> 
                {isSubscribed?(user?
                    (
                    <div>
                        {/* <Typography align="center" variant="h5">You're eating window for today is {Math.round(x)} Hours.</Typography> */}
                        {/* <History userEmail={currentUser.email}/> */}
                        {fastStartTime?(<FastingTimer fastPeriod = {x} fastStartTime = {fastStartTime} userEmail={user.email}/>):(null)}
                        {selfReflectionEnabled ? (
                            <Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
                                <Grid container justify="center" alignItems="center">
                                <Paper>
                                    <Typography align="center" variant="h5">
                                        Self Reflection
                                    </Typography>
                                    <SelfReflectionCalendar />
                                </Paper>
                                </Grid>
                            </Container>
                            ) : null}
                        {/* <UserRatingsCalendar/> */}
                        <div style={{paddingRight: 10, paddingLeft: 10}}>
                        <Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
                        <Grid container justify="center" alignItems="center">
                                <Paper>
                            <CalorieTracker />
                            </Paper>
                                </Grid>
                            </Container>
                            <Graph offset={offset} userEmail ={user.email} fastPeriod ={x} tempRequiredWeight={tempRequiredWeight}/>
                        </div>
                        {/*   */}
                    </div>):(null)
                    //the next line is where i need to send the user to update payment/sub
                ):(
                    <>
                    <Container component="main" maxWidth="sm">
                        <Paper style={{padding: 20}} elevation={5}>
                                <Typography align="center" variant="h5" gutterBottom={true}>You're subscription has ended or there was an issue with your payment method.</Typography>
                                <Button fullWidth={true} size="large" styles={{textDecoration: 'none'}} component={Link} to={"/subscribe"} variant="contained" color="primary">Click here to resubscribe</Button>
                        </Paper>
                    </Container>    
                    </>
                )
                }
            </SubscriptionStatus> 
        </div>
        </CalorieProvider>
        </>
    )
}