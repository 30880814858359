import React from 'react'
import MaterialTable from 'material-table'

import firebase, { initializeApp } from "firebase";
import "firebase/firestore"
import { Checkbox, Switch, Typography } from '@material-ui/core';
import TimePicker from '../../layout/TimePicker';
import { useState } from 'react';
import EnableNotifications from './notifications/EnableNotifications';

export default function Notifications(props) {
    const db = firebase.firestore()
    const [loading,setLoading] = useState(true)
    const [state,setState]= useState(null)
    const [notify,setNotify] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(true)
    const [notificationActive,setNotificationActive] = useState(null)

    if(notificationActive==null){
        init()
    }

    async function init(){
        const data = (await db.collection("users").doc(props.email).get()).data()
        setNotify(data.notify)
        if(notify === true){
            setState({
                columns: [
                    { title: 'Active', field: 'active' },
                    { title: 'Type', field: 'message' },
                    { title: 'Time', field: 'time' },
                ],
                data: [
                    {active: <Checkbox id="0" defaultChecked={data.notificationActive[0]} color="primary" onChange={handleChange}/>, message: 'Enter Weight Reminder', time: <TimePicker email={props.email}/>},
                    {active: <Checkbox id="1" defaultChecked={data.notificationActive[1]} color="primary" onChange={handleChange}/>, message: 'Eating Window Starting', time: "15 minutes before eating window"},
                    {active: <Checkbox id="2" defaultChecked={data.notificationActive[2]} color="primary" onChange={handleChange}/>, message: 'Eating Window Ending', time: "15 minutes before eating window ends"}
                    ]
             } )
            setNotificationActive(data.notificationActive)
        }
        setLoading(false)
    }

    async function handleChange (event){
        const active = event.target.checked
        const id = event.target.id
        //activate or de-activate notification in db
        const data = (await db.collection("users").doc(props.email).get()).data()
        //db.collection("users").doc("test1@test.com").set({notificationActive: oldArray},{merge: true})
        var newData = data.notificationActive
        newData[id] = active
        db.collection("users").doc(props.email).set({notificationActive: newData},{merge: true})
    };
    
    return (loading? (<div></div>):
        <div>
            {(notify && state!==null)
                ? 
                <div>
                    <MaterialTable 
                    style={{marginTop: "20px"}}
                    title={<Typography variant="h4">Text Notifications</Typography>}
                    columns={state.columns}
                    data={state.data}
                    options={{
                        search: false,
                        //selection: true
                        sorting: false,
                        paging: false
                    }}
                    />
                </div>
                :
                <EnableNotifications email={props.email} setNotify={setNotify} setNotificationActive={setNotificationActive}/>
            }
        </div>
    ) 
}
