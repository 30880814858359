import React, {useState,useEffect, useContext} from "react"

import firebase from "firebase/app"
import "firebase/firestore"

import ProgressBar from "./ProgressBar"
import { Container, Grid, Typography, Paper } from "@material-ui/core";
import { MuiPickersUtilsProvider,TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { useCalories } from "../../util/CalorieContext";


//https://github.com/kevinsqi/react-circular-progressbar
//This link is the progress bar I would like to use for the fasting timer. 

export default function FastingTimer(props) {
    const date  = new Date()
    const now = new Date()
    const timeZoneMS = date.getTimezoneOffset()*(1000*60)
    const timeOfDayMS = date%(24*60*60*1000) - timeZoneMS

    const db = firebase.firestore()
    const fastPeriod = Math.floor(props.fastPeriod)
    const fastPeriodMS = fastPeriod*60*60*1000
    const [fastStartTime, setFastStartTime] = useState(props.fastStartTime)
    const fastStartTimeMinutes = Math.round((fastStartTime%1)*60)
    const fastStartTimeMS = fastStartTime*60*60*1000
    const fastEndTime = fastStartTime + fastPeriod

    const fastEndTimeMS = fastEndTime*60*60*1000
    const [timeLeft, setTimeLeft] = useState([])
    const [selectedDate, handleDateChange] = useState(new Date().setHours(fastStartTime,fastStartTimeMinutes,0,0));
    const { totalCalories, setTotalCalories} = useCalories();

    const endDate = new Date().setHours(fastStartTime + fastPeriod,fastStartTimeMinutes,0,0)
    const fastEndDate = new Date(new Date().setHours(fastStartTime + fastPeriod,fastStartTimeMinutes,0,0))
    
    var settings = {color: "green"}

    //this sets the fasting start time when a user changes it
    function storeDateChange(newStartTime){
        const fastEndDate1 = new Date(new Date().setHours(newStartTime.getHours() + fastPeriod,newStartTime.getMinutes(),0,0))
        if((newStartTime.getHours() + newStartTime.getMinutes()) !== 0){
          db.collection("users").doc(props.userEmail).set({
            fastStartTime: newStartTime,
            fastEndTime: fastEndDate1
          },{ merge: true }).then(handleDateChange(newStartTime),setFastStartTime(newStartTime.getHours()+(newStartTime.getMinutes()/60)))
        } else {
          db.collection("users").doc(props.userEmail).set({
            fastStartTime: new Date(new Date().setHours(0,1,0,0)) ,
            fastEndTime: fastEndDate1
          },{ merge: true }).then(handleDateChange(newStartTime),setFastStartTime(newStartTime.getHours()+(newStartTime.getMinutes()/60)))
        }
    }
    //if I people to be able to make the start time every 15 minutes then I would need to change this so it also sets the minutes
    const fastEndTimeMinutes = (fastEndTime - Math.floor(fastEndTime))*60
    date.setHours( fastEndTime ,fastEndTimeMinutes,0,0)

    const calculateTimeLeft = (date) => {
        const difference = date - +new Date();
        let timeLeft = {};
        if (difference > 0 && difference < fastPeriodMS) {
          //This is the users eating window
          timeLeft = {
            difference: Math.floor((difference / 1000)),
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            color: "#21bf73",
            percentage: 100-Math.floor((difference/(props.fastPeriod*60*60*1000))*100),
            isFasting: false
          };
          //console.log(timeLeft.percentage)
        } else if(difference > fastPeriodMS){
          //This is before the eathing window starts 
          timeLeft = {
            difference: Math.floor((difference / 1000)),
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24) - fastPeriod,
            minutes: (60 + Math.floor((difference / 1000 / 60) % 60))%60   ,
            seconds: (60 + Math.floor((difference / 1000) % 60))%60  ,
            color: "#fd5e53",
            percentage: 30 + (timeOfDayMS/fastStartTimeMS)*70,
            isFasting: true
          };
        }else {
          //This is after the eating window ends
          
          const diff = Math.abs(timeOfDayMS-fastEndTimeMS)
          timeLeft = {
            diff: Math.floor((diff / 1000)),
            days: Math.floor(diff / (1000 * 60 * 60 * 24)),
            hours: Math.floor((24 - fastPeriod) - (now.getHours() - (fastEndTimeMS/1000/60/60) ) -1) ,//Math.floor((    (24*60*60*1000 - diff)      / (1000 * 60 * 60)) % 24),
            minutes: 60-Math.floor((diff / 1000 / 60) % 60)-1,
            seconds: 60-Math.floor((diff / 1000) % 60) - 1 ,
            color: "#fd5e53",
            percentage: Math.floor(  (((now.getHours() - (fastEndTimeMS/1000/60/60) ) +1)  /  (24 - fastPeriod))*100  ),//(Math.floor((    (24*60*60*1000 - diff)      / (1000 * 60 * 60)) % 24))/24*100 ,//(timeOfDayMS-(fastEndTimeMS))/(24*60*60*1000-(fastEndTimeMS))*30,
            isFasting: true
          };
        }
        return timeLeft;
      };

      useEffect(() => {
        const abortController = new AbortController()

        setTimeout(() => {
          setTimeLeft(calculateTimeLeft(date));

          return () => {
            abortController.abort();
          };

        }, 1000);
        
      });

    return(
        <Container maxWidth="xs">
          <Paper elevation={3}>
            
            <Typography align="center" variant="h3" style={{padding: "5px"}}>{timeLeft.isFasting?<span>You Are Fasting</span>:<span>You Are Eating</span>}</Typography>
            <Typography align="center" variant="h5" style={{padding: "5px"}}>Eating window: {Math.floor(fastPeriod)} Hours</Typography>
            
            <Typography align="center" variant="h5" gutterBottom={true}>Calories: {fastPeriod * 200}</Typography>
            <Typography align="center" variant="h5" gutterBottom={true}>Calories Remaining: {fastPeriod*200 - totalCalories}</Typography>

            <ProgressBar timeLeft = {timeLeft} color={settings.color}></ProgressBar>
            <div style={{padding: "30px"}}>
            <Grid justify="center" container spacing={3}>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <TimePicker label="Eating Start Time" value={selectedDate} onChange={storeDateChange} />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <TimePicker label="Eating End Time" value={endDate} onChange={storeDateChange} disabled={true} />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            </div>
            </Paper>
        </Container>
    )
}

