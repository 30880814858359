import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./auth/Auth";


export default function PrivateRoute(){
    const {currentUser} = useContext(AuthContext)

    console.log(currentUser)
    if(!!!currentUser){
        return (<Redirect to={"/signin"} />) 
    }



    return(
        <h1>This is private</h1>
    )
}