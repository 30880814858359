import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import SignUp from "./SignUp";
import HomePage from "./HomePage"
import SignIn from "./auth/SignIn";
import Navbar from "./Navbar"
import Dashboard from "./Dashboard"
import EnterWeight from "./data/EnterWeight";
import {AuthProvider} from "./auth/Auth"
import PrivateRoute from "./PrivateRoute"
import Subscribe from "./payment/Subscribe"
import Account from "./account/Account";
import ForgotPassword from "./auth/passwordReset/ForgotPassword";
import Contact from "./support/Contact"
import ConnectOAuth from "./referral/connectOAuth"
import ReferralCode from "./referral/referralCode"
import firebase from "firebase/app"
import "firebase/auth"
import SignUpSettings from "./account/settings/SignUpSettings";
import { useStore } from "../util/globalStore";
import RefundPolicy from "./policies/RefundPolicy";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import AffiliatePolicy from "./policies/AffiliatePolicy";
import AffiliateStats from "./referral/AffiliateStats";
import Affiliate from "./referral/Affiliate";
import SelfReflection from "./data/SelfReflection";
//import {StripeProvider} from 'react-stripe-elements';

function App() {
  //const [userInfo, setUser] = useState(null)
  const setUser = useStore(state => state.setUser)

  firebase.auth().onAuthStateChanged(async user => {
      setUser(user)
      // if (user) {
        
      //   setUser(user)
      // } else {
      //     //user is null
      //     //window.location.reload(false);
      //   console.log("user is null")
      // }
    });
   
  //return <SignUp />;
  return (
    <AuthProvider >
      <Router >
        <Navbar/>

        <Switch>
          <Route exact path = "/"><HomePage/></Route>
          <Route path = "/r/:ref"><HomePage/></Route>
          <Route path="/signin"><SignIn/></Route>
          <Route path="/signup"><SignUp/></Route>
          <Route path="/enterweight"><EnterWeight/></Route>
          <Route path="/dashboard"><Dashboard /></Route>
          <Route path="/private"><PrivateRoute/></Route>
          <Route path="/subscribe"><Subscribe/></Route>
          <Route path="/account"><Account/></Route>
          <Route path="/forgotPassword"><ForgotPassword/></Route>
          <Route path="/contact"><Contact/></Route>
          <Route path="/connect/affiliate"><ConnectOAuth/></Route>
          <Route path="/test/connect"><ReferralCode/></Route>
          <Route path="/initialSettings"><SignUpSettings/></Route>
          <Route path="/RefundPolicy"><RefundPolicy/></Route>
          <Route path="/PrivacyPolicy"><PrivacyPolicy/></Route>
          <Route path="/AffiliatePolicy"><AffiliatePolicy/></Route>
          <Route path="/affiliate"><Affiliate/></Route>
          <Route path="/selfreflection"><SelfReflection/></Route>
        </Switch>

      </Router>
    </AuthProvider>
  )
}

export default App;
